import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  CardBody,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader";
import config from "services/config";
import axios from "axios";
import ListUsers from "./ListUsers";
import ListUsersVendor from "./ListUsersVendor";

class Users extends PureComponent {
  state = {
    listUsers:[],
    listUsersVendor:[],
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
    actionFilter: {
      role_id: 2,
      username: ''
    }
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.getlistUsers();
    // this.getlistUsersVendor();
    axios.get(`https://prod-08.southeastasia.logic.azure.com:443/workflows/e52584b2d80b41e799c1877563462885/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=65H2dZuLetPrBA__uKBUHW2Nwbb243tWuyR1du94oto`)
    // axios.get(`https://jsonplaceholder.typicode.com/users`)
    .then(res => {
    //   const persons = res.data;
      this.setState({ listUsersVendor : res.data });
    })
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getlistUsersVendor = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .get(
        config.API_URL + "/users/users_vendor",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsersVendor: res.data.data,
            }, () => {
              if (this.state.isFilterLoading) {
                this.setState({
                  isFilterLoading: false,
                  isFilter: true,
                });
              }
              if (this.state.isResetLoading) {
                this.setState({
                  isResetLoading: false,
                  isFilter: true,
                });
              }
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  getlistUsers = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/users/users",
        this.state.actionFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            }, () => {
              if (this.state.isFilterLoading) {
                this.setState({
                  isFilterLoading: false,
                  isFilter: true,
                });
              }
              if (this.state.isResetLoading) {
                this.setState({
                  isResetLoading: false,
                  isFilter: true,
                });
              }
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleFilter = () => {
      this.getlistUsers();
  };

  handleReset = () => {
    this.setState(
      {
        actionFilter: {
          role_id: 2,
          username: ''
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getlistUsers();
            }
          );
        }
      }
    );
  };

  handleDetails = (e) => {
      this.props.history.push("/admin/user_sales_details/"+e.user_id);
  }
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    });
  };

  render() {
    return (
      <>
        <StandardHeader
          name="Users"
          parentName="Users"
          link="Users"
        />
        <Container className="mt--6" fluid>
        <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Users</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Name
                        </label>
                        <Input
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="username"
                          placeholder="Name"
                          value={this.state.actionFilter.username}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListUsersVendor
                  list={this.state.listUsersVendor}
                //   list={this.state.listUsers}
                  info={'Sales'}
                  details={this.handleDetails}
                />
              </div>
              <div className="card-wrapper">
                <ListUsers
                  list={this.state.listUsers}
                  info={'Cek api'}
                  details={this.handleDetails}
                />
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
