import { GET_DATA_PO_VENDOR } from "./poVendorActions";

const initialState = {
  dataPoVendor: [],
};

export default function poVendorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_PO_VENDOR:
      return {
        ...state,
        dataPoVendor: action.payload.poVendor,
      };
    default:
      return state;
  }
}
