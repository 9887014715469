import SignIn from "views/Auth/SignIn";
import Login from "views/Auth/Login";
import ListCompanies from "views/Admin/Companies/ListCompanies";
import ListUsers from "views/Admin/Users/Users";
import CreateCompany from "views/Admin/Companies/CreateCompany";
import CompanyDetails from "views/Admin/Companies/CompanyDetails";
import SignUp from "views/Auth/SignUp";
import ListJobsSCM from "views/Admin/JobsSCM/ListJobsSCM";
import CreateJobSCM from "views/Admin/JobsSCM/CreateJobSCM";
import JobSCMDetails from "views/Admin/JobsSCM/JobSCMDetails";
import PoDetails from "views/Admin/JobsSCM/PoSCM/PoDetails";
import ListPoVendor from "views/Admin/PoVendor/ListPoVendor";
import PoVendorDetails from "views/Admin/PoVendor/PoVendorDetails";
import { roles } from "services/roles";
import MyProfile from "views/Admin/MyProfile/MyProfile";
import Welcome from "views/Admin/Welcome/Welcome";

import vnListPo from "views/Vendor/PurchaseOrder/ListPo";
import vnListPoItem from "views/Vendor/OrderLineItems/ListPoItem";
import vnListOa from "views/Vendor/OrderAcknowledge/ListOrderAcknowledge";
import vnListOaF from "views/Vendor/OrderAcknowledge/FormOrderAcknowledge";
// import vnListOl from "views/Vendor/OrderLineItems/ListOrderLineItems";
import vnListOd from "views/Vendor/OrderDelivery/ListOrderDelivery";
import vnListaOdF from "views/Vendor/OrderDelivery/AddOrderDelivery";
import vnListOdF from "views/Vendor/OrderDelivery/FormOrderDelivery";
import vnListGr from "views/Vendor/GoodReceive/ListGoodReceive";
import vnMyprofile from "views/Vendor/Profile/MyProfile";


import vnSetting from "views/Vendor/Setting/setting";



const routes = [
  {
    name: "Order Fulfillment",
    icon: "fa fa-tasks text-dark",
    state: "orderFulfillmentCollapse",
    role: roles(2)||roles(1),
    collapse: true,
    views: [
      //SCM
      {
        path: "/jobs-scm",
        name: "Purchaser",
        miniName: "P",
        component: ListJobsSCM,
        role: roles(1),
        layout: "/admin",
      },
      {
        path: "/create-job-scm",
        name: "Create Job",
        component: CreateJobSCM,
        layout: "/admin",
        role: roles(1),
        child: false,
      },
      {
        path: "/job-scm-details/:jobId",
        name: "Job Details",
        component: JobSCMDetails,
        layout: "/admin",
        role: roles(1),
        child: false,
      },
      {
        path: "/po-scm-details/:poId",
        name: "PO Details",
        component: PoDetails,
        layout: "/admin",
        role: roles(1),
        child: false,
      },

      // Vendor
      {
        path: "/jobs-vendor",
        name: "Supplier",
        component: ListPoVendor,
        miniName: "S",
        role: roles(2 || 3),
        layout: "/admin",
      },
      {
        path: "/po-vendor-details/:poId",
        name: "Po Details",
        component: PoVendorDetails,
        role: roles(2 || 3),
        layout: "/admin",
        child: false,
      },
    ],
  },

  

  {
    name: "Master Data",
    icon: "fa fa-cog text-dark",
    state: "masterDataCollapse",
    role: roles(1),
    collapse: true,
    layout: "/admin",
    views: [
      {
        path: "/companies",
        name: "Companies",
        miniName: "C",
        component: ListCompanies,
        role: roles(1),
        layout: "/admin",
      },
      {
        path: "/create-company",
        name: "Create Company",
        component: CreateCompany,
        layout: "/admin",
        role: roles(1),
        child: false,
      },
      {
        path: "/company-details/:companyId",
        name: "Company Details",
        component: CompanyDetails,
        layout: "/admin",
        role: roles(1),
        child: false,
      },
      {
        path: "/users",
        name: "Users",
        miniName: "C",
        component: ListUsers,
        role: roles(1),
        layout: "/admin",
      },
    ],
  },

  {
    path: "/myprofile",
    name: "My Profile",
    miniName: "MP",
    component: MyProfile,
    layout: "/admin",
    child: false,
  },

  {
    path: "/welcome",
    name: "Welcome",
    miniName: "W",
    component: Welcome,
    layout: "/admin",
    child: false,
  },
  {
    path: "/purchase-order",
    name: "Purchase Order",
    // miniName: "W",
    component: vnListPo,
    role: roles(3),
    layout: "/vendor",
    child: false,
  },
  {
    path: "/purchase-order-item/:idPO",
    name: "Purchase Order item",
    // miniName: "W",
    component: vnListPoItem,
    role: roles(3),
    layout: "/vendor",
    child: false,
  },
  {
    path: "/order-acknowledge",
    name: "Order Acknowledge",
    // miniName: "W",
    component: vnListOa,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/order-acknowledge-form",
    name: "Order Acknowledge Form",
    // miniName: "W",
    component: vnListOaF,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/setting",
    // name: "Order Acknowledge Form",
    // miniName: "W",
    component: vnSetting,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  
  // {
  //   path: "/order-line-items",
  //   name: "Order Line Items",
  //   // miniName: "W",
  //   component: vnListOl,
  //   layout: "/vendor",
  //   role: roles(3),
  //   child: false,
  // },
  {
    path: "/order-delivery",
    name: "Order Delivery",
    component: vnListOd,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/add-order-delivery",
    name: "Add Order Delivery",
    component: vnListaOdF,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/order-delivery-form/:id",
    name: "Order Delivery Form",
    component: vnListOdF,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/good-receive",
    name: "Good Receive",
    // miniName: "W",
    component: vnListGr,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },
  {
    path: "/my-profile",
    name: "My Profile",
    // miniName: "W",
    component: vnMyprofile,
    layout: "/vendor",
    role: roles(3),
    child: false,
  },


  //auth
  {
    path: "/sign-in",
    name: "Sign In",
    miniName: "L",
    component: SignIn,
    layout: "/auth",
    child: false,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    miniName: "C",
    component: SignUp,
    layout: "/auth",
    child: false,
  },
  {
    path: "/login",
    name: "Login",
    miniName: "C",
    component: Login,
    layout: "/auth",
    child: false,
  },
];

export default routes;
