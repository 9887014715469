import Validator from "validator";

export const validateLogin = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }

  if (!data.password) {
    errors.password = "Field is required";
  }

  return errors;
};
export const validateFormOrderAcknowledge = (data) => {
  const errors = {};

  if (!data.purchase_order) {
    errors.purchase_order = "Field is required";
  }
  if (!data.qty) {
    errors.qty = "Field is required";
  }
  if (!data.purchase_order_item) {
    errors.purchase_order_item = "Field is required";
  }
  if (!data.est_time) {
    errors.est_time = "Field is required";
  }
  if (!data.est_date) {
    errors.est_date = "Field is required";
  }
  return errors;
};



export const validateCreateNewAccount = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }
  if (!data.username) {
    errors.username = "Field is required";
  }
  if (!data.password) {
    errors.password = "Field is required";
  }
  if (!data.confirm_password) {
    errors.confirm_password = "Field is required";
  }

  if (data.password !== data.confirm_password) {
    errors.confirm_password = "Not Matching";
  }
  if (!data.company_id) {
    errors.company_id = "Field is required";
  }

  if (data.company_id !== data.company_id_ver) {
    errors.company_id_ver = "Wrong id, please check again";
  }

  return errors;
};
export const validateCompany = (data) => {
  const errors = {};

  if (!data.company_name) {
    errors.company_name = "Field is required";
  }
  if (!data.company_phone) {
    errors.company_phone = "Field is required";
  }
  if (!data.company_address) {
    errors.company_address = "Field is required";
  }

  if (!data.company_business_fields) {
    errors.company_business_fields = "Field is required";
  }

  if (!data.company_sub_business_fields) {
    errors.company_sub_business_fields = "Field is required";
  }
  return errors;
};
export const validateUser = (data) => {
  const errors = {};
  if (data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = "Invalid email";
    }
  } else if (!data.email) {
    errors.email = "Field is required";
  }
  if (!data.username) {
    errors.username = "Field is required";
  }
  if (!data.role_id) {
    errors.role_id = "Field is required";
  }
  return errors;
};
export const validateJobs = (data) => {
  const errors = {};

  if (!data.job_name) {
    errors.job_name = "Field is required";
  }
  if (!data.job_description) {
    errors.job_description = "Field is required";
  }
  if (!data.job_no) {
    errors.job_no = "Field is required";
  }

  return errors;
};

export const validatePo = (data) => {
  const errors = {};
  if (!data.job_id) {
    errors.job_id = "Field is required";
  }
  if (!data.company_id) {
    errors.company_id = "Field is required";
  }
  if (!data.po_no) {
    errors.po_no = "Field is required";
  }
  if (!data.po_date) {
    errors.po_date = "Field is required";
  }
  if (!data.po_delivery_date) {
    errors.po_delivery_date = "Field is required";
  }
  if (!data.po_file) {
    errors.po_file = "Field is required";
  }
  return errors;
};

export const validateDocument = (data) => {
  const errors = {};
  
  if (!data.document_no) {
    errors.document_no = "Field is required";
  }
  if (!data.document_name) {
    errors.document_name = "Field is required";
  }
  return errors;
};

export const validateTaskFeed = (data) => {
  const errors = {};
  
  if (!data.start_date) {
    errors.start_date = "Field is required";
  }
  if (!data.due_date) {
    errors.due_date = "Field is required";
  }
  if (!data.task_feed_description) {
    errors.task_feed_description = "Field is required";
  }
  return errors;
};

export const validateProfile = (data) => {
  const errors = {};
  if (!data.username) {
    errors.password = "Field is required";
  }
  if (!data.old_password) {
    errors.old_password = "Field is required";
  }
  if (!data.new_password) {
    errors.new_password = "Field is required";
  }
  if (!data.confirm_password) {
    errors.confirm_password = "Field is required";
  }
  if (data.confirm_password !== data.new_password) {
    errors.confirm_password = "Confirm password not matching";
  }
  return errors;
};