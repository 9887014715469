import React, { PureComponent } from "react";

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Form
} from "reactstrap";

// core components
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateCompany } from 'services/validate';

//services
import config from "services/config";

//packages
import axios from "axios";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
class CreateCompany extends PureComponent {
  state = {
    data: {
      company_name: "",
      company_description: "",
      company_address: "",
      company_phone: "",
      company_business_fields: '',
      company_sub_business_fields: ''
    },
    dataExcel: {},
    isUploadExcel: false,
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  postDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramError = validateCompany(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      axios
        .post(config.API_URL + "/companies/create_company", this.state.data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": config.TOKEN
          },
        })
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
            }
          },
          (err) => {
            if (this.mounted) {
              if (err.response !== undefined) {
                this.failedAlert(err.response.data.message);
                this.setState({ isLoading: false });
              }
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      })
    }
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleSubmit = () => {
    this.postDataToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleUploadExcel = () => {
    this.setState({
      isUploadExcel: !this.state.isUploadExcel
    })
  }
  onFileChange = (e) => {
    this.setState({ dataExcel: e.target.files[0] });
  };

  onFileUpload = () => {
    this.setState({ isLoading: true });
    if(this.state.dataExcel != null){
        let file = this.state.dataExcel;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.name.match(/\.(xlsx|xls)$/)) {
            var formData = new FormData();
            formData.append("file", this.state.dataExcel);
            axios
              .post(config.API_URL + "/companies/bulk_companies", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization": config.TOKEN
                },
              })
              .then(
                (res) => {
                  if (this.mounted) {
                    this.successAlert(res.data.message);
                    this.setState({ isLoading: false });
                  }
                },
                (err) => {
                  if (this.mounted) {
                    if (err.response !== undefined) {
                      this.failedAlert(err.response.data.message);
                      this.setState({ isLoading: false });
                    }
                  }
                }
              );
        } else {
            this.failedAlert("Invalid Format File");
            this.setState({ isLoading: false });
        }
    } else {
        this.failedAlert("Field is required");
        this.setState({ isLoading: false });
    }
  };
  render() {
    return (
      <>
        <StandardHeader name="Create Company" parentName="Companies" link="companies" />
        <Container className="mt--6" fluid>
          <Row className="mb-4">
            <Col>
              <Button
                color="success"
                type="button"
                className="btn-icon"
                onClick={() => this.handleUploadExcel()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fa fa-file-excel-o" />
                </span>
                <span className="btn-inner--text">Bulk Data Companies</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.isUploadExcel &&
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Bulk Data Companies</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label className="form-control-label">Excel</label>
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileLang"
                                lang="en"
                                type="file"
                                onChange={(e) => this.onFileChange(e)}
                              />
                              <label className="custom-file-label" htmlFor="customFileLang">
                                Select file
                                    </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col style={{ marginTop: 20 }} className="col-6 col-md-3 col-sm-6" xs="12">
                          <Button
                            block
                            color='success'
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.onFileUpload()}
                          >
                            {
                              this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                        Submit
                                    </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Submit</span>
                              )
                            }
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Create Company</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Company Name</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_name"
                            placeholder="Company Name"
                            value={this.state.data.company_name}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_name ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_name}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Business Fields</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_business_fields"
                            placeholder="Company Business Fields"
                            value={this.state.data.company_business_fields}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_business_fields ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_business_fields}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Sub Business Fields</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_sub_business_fields"
                            placeholder="Company Sub Business Fields"
                            value={this.state.data.company_sub_business_fields}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_sub_business_fields ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_sub_business_fields}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Company Phone</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="company_phone"
                            placeholder="Company Phone"
                            value={this.state.data.company_phone}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_phone ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_phone}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Company Address</label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="company_address"
                            placeholder="Company Address"
                            value={this.state.data.company_address}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_address ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_address}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Description</label>
                          <Input
                            type="textarea"
                            onKeyPress={this.keyPressed}
                            name="company_description"
                            placeholder="Company Description"
                            value={this.state.data.company_description}
                            onChange={this.handleChangeString}
                            required
                            className={this.state.paramError.company_description ? "is-invalid" : ""}
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.company_description}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Submit
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Submit</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default CreateCompany;
