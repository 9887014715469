/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

// reactstrap components
import { Card, CardHeader, Alert, UncontrolledTooltip } from "reactstrap";
import moment, { parseTwoDigitYear } from "moment";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const TapPo = (props) => {
  const info = props.info;
  // const actionsRole = (cell, row) => {
  //    if(cell === 1) return 'SCM';
  //    if(cell === 2) return 'Vendor';
  //   }
  //   const actionsIsActive = (cell, row) => {
  //     if(cell === 1) return 'Yes';
  //     if(cell === 0) return 'No';
  //    }
  const actionsFormatter = (cell, row) => {
      return (
        <>
          {/* <a
            className="table-action"
            id={"po-item"}
            style={{ cursor: "pointer" }}
            onClick={() => props.item(row)}
          >
           <i className="fa fa-th-list" aria-hidden="true"/>

          </a>&nbsp; */}
          <a
            className="table-action"
            id={"po"}
            style={{ cursor: "pointer" }}
            onClick={() => props.detail(row)}
          >
          <i className="fa fa-th-large" aria-hidden="true"/>

          </a>
          <UncontrolledTooltip delay={0} target={"po"}>
          PO Details
          </UncontrolledTooltip>
          {/* <a
            className="table-action table-action-delete"
            id={"tooltip12313"}
            style={{ cursor: "pointer" }}
            onClick={() => props.update(row)}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target={"tooltip12313"}>
            Update
          </UncontrolledTooltip> */}
        </>
      );
   
   
  };
  // const rowEvent = {
  //   onDoubleClick: (e, row, rowIndex) => {
  //     return(console.log(row))
  //   }
  // };
  // const handleDate =(cell) =>{
  //   return (cell.substr(0,10))
  // }
  const selectRow = {
    selected: actionsFormatter,
  };
  const rowEvent={
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
    },
    onMouseEnter: (e, row, rowIndex) => {
      console.log(`enter on row with index: ${rowIndex}`);
    }
  }
  return (
    <>
      <Card>
        <ToolkitProvider
        hover
        selectRow={ selectRow }
          data={props.list}
          keyField="po"
            // onDoubleClick={() => console.log("cek")}
            // onDoubleClick={() => console.log(props)}
            rowEvents= {rowEvent}
          columns={[
            {
              dataField: "podate",
              text: "PO DATE",
              headerAlign: "center",
              align:"center",
              sort: true,
              formatter: (cell) =>{
                if (cell===undefined){
                  return null
                }else{
                  return(moment(cell).format("DD-MM-YYYY"))
                }
            }
            },
            {
              dataField: "po",
              text: "PURCHASE ORDER",
              sort: true,
              formatter:(cell,row)=>{
                return (
                  <a
                  className="table-action font-weight-bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.item(row)}
                >
                 <i className="fa fa-arrow-circle-right" aria-hidden="true"/>{" "}
                  <u>
                    {cell}
                    </u>
                </a>
                )
              }
            },
            {
              dataField: "ct",
              text: "Quantity",
              sort: true,
              headerAlign:"center",
              align:"center",
            },
            {
              dataField: "amounttotal",
              text: "AMOUNT TOTAL",
              headerAlign:"center",
              align:"right",
              sort: true,
            },
            {
              dataField: "currency",
              text: "CURRENCY",
              sort: true,
              headerAlign:"center",
              align:"center",
            },
            {
              // dataField: "currency",
              text: "STATUS",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter:(cell,row)=>{
                if (cell=="Products Received"){
                  return (<><b>Product Received</b></>)
                } else if (cell=="Canceled"){
                  return (<><b>Canceled</b></>)
                } else if (cell=="Billed"){
                  return (<><b>Completed</b></>)
                } else if (cell="Submitted"){
                  return (<><b>Submitted</b></>)
                } else if (cell="Released"){
                  return (<><b>Released</b></>)
                } else if (cell="Draft"){
                  return (<><b>Draft</b></>)
                }
              }
            },
            {
              // dataField: "currency",
              text: "SUB STATUS",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter:(cell,row)=>{
                if (cell==1){
                  return (<><b>Fulfilled</b></>)
                } else if (cell==1){
                  return (<><b>Partial</b></>)
                } else if (cell==1){
                  return (<><b>Available</b></>)
                } else {
                  return (<><b>No quantity</b></>)
                }
              }
            },
            {
              // dataField: "currency",
              text: "PAYMENT",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter:(cell,row)=>{
                if (cell==1){
                  return (<><b>Paid</b></>)
                } else if (cell==1){
                  return (<><b>Billed</b></>)
                } else {
                  return (<><b>Not Available</b></>)
                }
              }
            },
            // {
            //   dataField: "podate",
            //   text: "PO DATE",
            //   sort: true,
            // },
            // {
            //   dataField: "gramount",
            //   text: "AMOUNT GR",
            //   sort: true,
            // },
            // {
            //   dataField: "amountbilled",
            //   text: "AMOUNT BILLED",
            //   sort: true,
            // },
           
            // {
            //   dataField: "podate",
            //   text: "PURCHASE ORDER DATE",
            //   sort: true,
            // },
            // {
            //   dataField: "expected_date",
            //   text: "EXPECTED DATE",
            //   sort: true,
            //   // formatter: handleDate,
            // },
           
            // {
            //   dataField: "role_id",
            //   text: "Role",
            //   sort: true,
            //   formatter: actionsRole
            // },
            // {
            //   dataField: "is_active",
            //   text: "Active",
            //   formatter: actionsIsActive
            // },
            // {
            //   sort: true,
            //   text: "Actions",
            //   headerAlign:"center",
            //   align:"center",
            //   formatter: actionsFormatter,
            // },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
          {info}
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TapPo;
