import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  // Card,
  // CardHeader
} from "reactstrap";
//services
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
import moment from "moment";

//packages
// import Pagination from "react-bootstrap-pagination-logic";

//redux
// import { connect } from "react-redux";
// import { getDataPoVendor } from "./Redux/poVendorActions";
// import moment from "moment";
// react component used to create sweet alerts
// import SweetAlert from "react-bootstrap-sweetalert";
import Spacer from "components/Headers/Spacer";
import StandardHeader from "components/Headers/Breadcrumb";
import ListOD from "./TabOrderDelivery";
import tableOrderDelivery from "./TabOrderDelivery";
// import ListPOItem from "./TabPoItem";
// class ListOrderDelivery extends PureComponent {
class ListOrderDelivery extends Component {
  state = {
    taskOA: false,
    dataOA: [],
    dataPo: [],
    list:[],
    isLoading: false,
    alert: null,
    checked: false,
    actionFilter: {
      id:config.COMPANY_NAME,
      idUser:config.USER_ID,
      table: "1",
      po: "",
      // from_date: moment().subtract(1, "years").format("YYYY-MM-DD"),
      // to_date: moment().add(1, "days").format("YYYY-MM-DD"),
    },
    OAid: "",
    OA: [],
    ver_id: "",
    po: "",
    po_item_no: "",
    data: {
      vendor:config.COMPANY_ID,
      track_no: "",
      est_date: "",
      qty: "",
      ref: "",
      reject: "",  
      // ver_oa: e.ver_po_id,
      // po: e.po,
      // po_item_no: e.po_item_no,
      // track_no: e.tracking_no,
      // est_date: e.date_expected.substr(0,10),
      // qty: e.quantity,
    },
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  //   componentDidmount() {
  //   console.log("cek");
  //   this.mounted = true;
  //   this.getListUsers();

  //   axios.get(`https://jsonplaceholder.typicode.com/users`)
  //   .then(res => {
  //     console.log(res.data)
  //     // this.setState({ person: persons });
  //   })
  // }
  componentDidMount = async () => {
    this.mounted = true;
    // this.getlistUsers();
    // this.getlistUsersVendor();
    // axios.get(`https://prod-08.southeastasia.logic.azure.com:443/workflows/e52584b2d80b41e799c1877563462885/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=65H2dZuLetPrBA__uKBUHW2Nwbb243tWuyR1du94oto`)
    // // axios.get(`https://jsonplaceholder.typicode.com/users`)
    // .then(res => {
    //   // console.log(res.data)
    //   // const persons = res.data;
    //   this.setState({ person: res.data });
    // //   const persons = res.data;
    //   // this.setState({ listUsersVendor : res.data });
    // })
    this.getPO();
    this.handleToggle = this.handleToggle.bind(this);
    this.getList();
  };
  getList = () => {
    axios
      .post(config.API_URL + "/od/list_order_delivery", this.state.vendor, {
      // .post(config.API_URL + "/po/list_po_line_item", this.state.vendor, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              list: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  handleToggle(e) {
    let isChecked = e.target.checked;
    if (isChecked === true) {
      this.setState(
        {
          data: {
            ...this.state.data,
            [e.target.name]: 1,
          },
        },()=>this.confirmAlert()
      );
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: 0,
        },
      },()=>console.log(this.state.data));
    }
  }
  getPO = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/po/list_po", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                dataPo: res.data.data,
                // countTable1: res.data.data.length,
              },
              () => {
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getListPO = () => {
  //   if (this.state.isResetLoading === false) {
  //     if (this.state.isFilterLoading === false) {
  //       this.setState({
  //         isFilterLoading: true,
  //       });
  //     }
  //   }
  //   axios
  //     .post(config.API_URL + "/po/list_po_line_item", this.state.actionFilter, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: config.TOKEN,
  //       },
  //     })
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               dataPoItem: res.data.data,
  //               // countTable1: res.data.data.length,
  //             }
  //             // () => {
  //             //   if (this.state.isFilterLoading) {
  //             //     this.setState({
  //             //       isFilterLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             //   if (this.state.isResetLoading) {
  //             //     this.setState({
  //             //       isResetLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             // }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  // getOA = () => {
  //   // if (this.state.isResetLoading === false) {
  //   //   if (this.state.isFilterLoading === false) {
  //   //     this.setState({
  //   //       isFilterLoading: true,
  //   //     });
  //   //   }
  //   // }
  //   axios
  //     .post(config.API_URL + "/po/list_po", this.state.OAid, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: config.TOKEN,
  //       },
  //     })
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               po: res.data.data[0].po,
  //               po_item_no: res.data.data[0].po_item_no,
  //               track_no: res.data.data[0].tracking_no,
  //               material_short_text: res.data.data[0].material_short_text,
  //               est_date: res.data.data[0].date_expected,
  //               qty: res.data.data[0].quantity,
  //               // countTable1: res.data.data.length,
  //             }
  //             // () => {
  //             //   if (this.state.isFilterLoading) {
  //             //     this.setState({
  //             //       isFilterLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             //   if (this.state.isResetLoading) {
  //             //     this.setState({
  //             //       isResetLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             // }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  postOA = () => {
    // if (this.st;ate.isResetLoading === false) {
    //   if (this.state.isFilterLoading === false) {
    //     this.setState({
    //       isFilterLoading: true,
    //     });
    //   }
    // }
    axios.post(config.API_URL + "/po/form_order_acknowledge", this.state.data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                taskOA: !this.state.taskOA
            //   //   po: res.data.data[0].po,
            //   //   po_item_no: res.data.data[0].po_item_no,
            //   //   track_no: res.data.data[0].tracking_no,
            //   //   est_date: res.data.data[0].date_expected,
            //   //   qty: res.data.data[0].quantity,
            //   //   ref: res.data.data[0].ref,
            //   //   dec: res.data.data[0].reject,
            //   //   // countTable1: res.data.data.length,
            //   // }
            //   () => {
            //     if (this.state.isFilterLoading) {
            //       this.setState({
            //         isFilterLoading: false,
            //         isFilter: true,
            //       });
            //     }
            //     // if (this.state.isResetLoading) {
            //     //   this.setState({
            //     //     isResetLoading: false,
            //     //     isFilter: true,
            //     //   });
            //     // }
              }
              ,()=>this.successAlert(res.data.message)
              );
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(this.state.filledDataTaskFeedAssign.task_feed_description);
              this.setState({ isLoading: false });
            }
          }
        }
      );
  };
  handleAlertFormOA = ()=>{
    this.setState(
      {
        alert: null,
      },
      ()=>this.getListPO(),
      );
  }
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleAlertFormOA()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={1000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  confirmAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          showCloseButton
          style={{ display: "block" }}
          title="Confirmation"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Yes, Reject"
          cancelBtnBsStyle="link"
          cancelBtnText="Cancel"
          btnSize=""
          timeout={5000}
        >
          "Order Acknowledge will be rejected ?"
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handleChangeString = (event) => {
    this.setState({
      actionFilter: {
        ...this.state.actionFilter,
        [event.target.name]: event.target.value,
      },
    },() => {
      this.getPO();
    });
  };
  // getListPO = () => {
  //   axios.post(`https://prod-21.southeastasia.logic.azure.com:443/workflows/b1d9a608d9a640ffa165cb25186c00b0/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ao8HYMOI1oggG-qzmlmATqMoCUm7zpevDLc18GsOdCw`)
  //   .then(res => {
  //     console.log(res.data)
  //   //   // const persons = res.data;
  //     this.setState({ data1: res.data });
  //   // //   const persons = res.data;
  //   //   // this.setState({ listUsersVendor : res.data });
  //   })
  // }
  // getListPO = () => {
  //   axios.post(`https://prod-21.southeastasia.logic.azure.com:443/workflows/b1d9a608d9a640ffa165cb25186c00b0/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ao8HYMOI1oggG-qzmlmATqMoCUm7zpevDLc18GsOdCw`)
  //   .then(res => {
  //     console.log(res.data)
  //   //   // const persons = res.data;
  //     this.setState({ data1: res.data });
  //   // //   const persons = res.data;
  //   //   // this.setState({ listUsersVendor : res.data });
  //   })
  // }
  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/companies/view_vendor",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleItem = (e) =>{
    this.props.history.push("/vendor/purchase-order-item/"+ e.po);
  }

  handleOA = (e) => {
    console.log(e)
    // console.log(e.ver_po_id)
    this.setState({
      data: {
        ver_oa: e.ver_po_id,
        po: e.po,
        po_item_no: e.po_item_no,
        track_no: e.tracking_no,
        est_date: e.date_expected.substr(0,10),
        material_short_text: e.material_short_text,
        qty: e.quantity,
        reject:0
      },
      taskOA: !this.state.taskOA,
    });
  };
  toggleModal = () => {
    this.setState({
      data: {
        po: "",
        po_item_no: "",
        track_no: "",
        est_date: "",
        qty: "",
        ref: "",
        reject:0
      },
      taskOA: !this.state.taskOA,
    });
  };
  handleUpdate = (e) => {
    console.log(e);
  };

  handleStringOA = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    },()=>console.log(this.state.data));
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleHover = () => {
    this.setState({ addHover: !this.state.addHover });
  };
  handleLeave = () => {
    this.setState({ addHover: !this.state.addHover });
  };

  render() {
    const hoverStyle = {
      fontSize: "13px",
      cursor: "pointer",
      objectFit: "cover",
      color: this.state.addHover ? "blue" : "grey",
    };
    return (
      <div>
        <Col sm="6">
          {/* <TaskFeedVendor
                list={this.state.dataTaskFeed}
                info={"Task Feed (Purchaser)"}
                update={this.handleUpdateStatus}
                toggleModal={this.toggleModal}
              /> */}
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.taskOA}
            toggle={() => this.toggleModal("taskOA")}
          >
            {/* <div className="modal-header">
                  <FormGroup>
                  <h6 className="modal-title" id="modal-title-default">
                    {this.state.data.po}
                  </h6>
                    <small className="text-muted">Order acknowledge PO</small> <br />
                    </FormGroup>
                    <hr />
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("taskOA")
                    }
                  >
                    <span aria-hidden={true}>X</span>
                  </button>
                </div> */}
            <div className="modal-body">
              <Row>
                {/* <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          disabled
                          value={
                            this.state.filledTaskFeed.task_feed_description
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          value={moment(
                            this.state.filledTaskFeed.start_date
                          ).format("YYYY-MM-DD")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          disabled
                          value={moment(
                            this.state.filledTaskFeed.due_date
                          ).format("YYYY-MM-DD")}
                        />
                      </FormGroup>
                    </Col> */}

                <Col xs="12">
                  <Row>
                    <Col xs="10">
                      <FormGroup>
                        <h6 className="modal-title" id="modal-title-default">
                          {this.state.data.po}
                        </h6>
                        <small className="text-muted">
                          Order acknowledge PO
                        </small>{" "}
                        <br />
                      </FormGroup>
                    </Col>
                    <Col xs="2">
                      <FormGroup>
                        <button
                          aria-label="Close"
                          className="close text-right"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => this.toggleModal("taskOA")}
                        >
                          <span aria-hidden={true}>X</span>
                        </button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          PO Item
                        </label>
                        <Input
                          type="textarea"
                          // onChange={this.handleStringOA}
                          // required
                          disabled
                          value={this.state.data.material_short_text}
                          />
                      </FormGroup>
                </Col>
                <Col xs="12">
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <label className="form-control-label">
                          Po line item
                        </label>
                        <Input
                          type="text"
                          name="po"
                          // onChange={this.handleStringOA}
                          // required
                          disabled
                          value={this.state.data.po_item_no}
                          />
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <label className="form-control-label">Quantity</label>
                        <Input
                          type="text"
                          name="qty"
                          // disabled
                          // onChange={this.handleStringOA}
                          // required
                          value={this.state.data.qty}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12">
                  <Row>
                    <Col xs="8">
                      <FormGroup>
                        <label className="form-control-label">
                          Estimate date <small className="text-muted">mm/dd/yy</small>
                        </label>
                        <Input
                          type="date"
                          name="est_date"
                          onChange={this.handleStringOA}
                          // required
                          // disabled
                          value={this.state.data.est_date}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Time
                        </label>
                        <Input
                          type="time"
                          name="est_time"
                          // onChange={this.handleStringOA}
                          // required
                          value={this.state.data.ref}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <label className="form-control-label">Reference</label>
                    <Input
                      type="textarea"
                      name="ref"
                      placeholder="reference"
                      onChange={this.handleStringOA}
                      required
                      value={this.state.data.ref}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                    {/* <label className="form-control-label">Reject</label>
                    <Input
                      type="select"
                      onKeyPress={this.keyPressed}
                      name="reject"
                      placeholder="Complete"
                      onChange={this.handleStringOA}
                      required
                      value={this.state.data.reject}
                    >
                      <option value={""}>Select</option>
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </Input> */}
                  <Row>
                    <Col xs="7"> <label className="form-control-label">Reject</label></Col>
                    <Col xs="5" className="text-right">

                  <FormGroup>
                <label className="custom-toggle custom-toggle-dark mr-1">
                                <Input
                                  type="checkbox"
                                  name="reject"
                                  value={this.state.data.reject}
                                  onChange={(e) => this.handleToggle(e)}
                                  checked={
                                    this.state.data.reject[
                                      this.state.data
                                    ]
                                  }
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                  />
                              </label>
                  </FormGroup>
                                  </Col>
                                </Row>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
            <Button
                className="text-center"
                color="danger"
                data-dismiss="modal"
                type="button"
                block
                onClick={() => this.toggleModal("taskOA")}
              >
                Close
              </Button>
              <Button
                className="text-center"
                color="success"
                type="button"
                block
                onClick={() => this.postOA()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
        </Col>
        <Spacer />
        <Container fluid>
          <div className="card-wrapper">
          <tableOrderDelivery
                list={this.state.list}
                info={
                  <Row>
                    <Col className=" col-md-8  col-sm-4  col-12">
                      <StandardHeader
                        parentName="Order Delivery"
                        link="order-delivery"
                      />
                    </Col>
                    <Col className=" col-md-1  col-sm-4  col-12 text-right">
                      <a
                        style={hoverStyle}
                        onMouseEnter={() => this.handleHover()}
                        onMouseLeave={() => this.handleLeave()}
                        onClick={() => this.handleAdd()}
                      >
                        <i className="ni ni-fat-add" aria-hidden="true" /> Add
                      </a>
                    </Col>
                    <Col className=" col-md-3  col-sm-3  col-12 ml-auto">
                      {/* <Col md={9} className="text-left"> */}
                      <InputGroup size="sm">
                        <Input
                          bsSize="sm"
                          type="search"
                          // autofocus="autofocus"
                          className="search"
                          id="search"
                          placeholder="Search"
                          result
                        />
                        <InputGroupText className="pt-0 pb-0 pl-2 pr-2">
                          <a
                            href=" "
                            style={{
                              cursor: "pointer",
                              objectFit: "cover",
                              color: "grey",
                            }}
                          >
                            <i
                              class="fa fa-search"
                              aria-hidden="true"
                              for="search"
                            ></i>
                          </a>
                        </InputGroupText>
                      </InputGroup>
                      {/* </Col> */}
                    </Col>
                  </Row>
                }
                update={this.handleUpdateUser}
                delete={this.confirmAlertUser}
              />
            </div>
          <div className="card-wrapper">
            <ListOD
              list={this.state.list}
              info={
                <Row>
                  <Col className=" col-md-3  col-sm-6  col-12"  >
                  <StandardHeader
                        parentName="Order Delivery"
                        link="order-delivery"
                      />
                    </Col>
                  <Col className=" col-md-3  col-sm-12  col-12 ml-auto">
                    <InputGroup size="sm" style={{position: "relative",display: "inline-block" }}>
                      
                      <Input
                        bsSize="sm"
                        type="search"
                        className="search"
                        id="search"
                        placeholder="Search"
                        result
                        onKeyPress={this.keyPressed}
                        name="invoice"
                        value={this.state.actionFilter.invoice}
                        onChange={this.handleChangeString}
                      />
                        {this.state.isFilterLoading ? (
                          <>
                          <i className="fa-li fa fa-spinner fa-spin text-muted"    aria-hidden="true"
                          for="search"/>
                          </>
                          ) : null
                          }
                      {this.state.actionFilter.invoice ?
                      <i className="fa fa-times" aria-hidden="true"/> : null
                    }
                    </InputGroup>
                  </Col>
                  
                </Row>
              }
              item={this.handleItem}
              detail={this.handleUpdate}
            />
          
          </div>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default ListOrderDelivery;
