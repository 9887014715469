import StandardHeader from "components/Headers/StandardHeader";
import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import ListUsers from "./ListVendor";
import axios from "axios";
import config from "services/config";

class Welcome extends PureComponent {
  state = {
    data: {
      company_name: "",
      company_description: "",
      company_phone: "",
      company_address: "",
      company_business_fields: '',
      company_sub_business_fields: ''
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "company_id",
      order_by_direction: "DESC",
      company_name: "",
    },
    dataUser: {
      username: "",
      password: "",
      email: "",
      role_id: 0,
      is_active: 0
    },
    dataUpdateUser: {
      username: "",
      email: "",
      role_id: 0,
      is_active: 0
    },
    registration_no: '',
    updateUser: false,
    listCompanies: [],
    listUsers: [],
    isLoading: false,
    alert: null,
    paramErrorCompany: {},
    paramErrorUser: {},
  };

  componentDidUnmount() {
    this.mounted = true;
    this.getListUsers();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/companies/view_vendor",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  render() {
    return (
      <div>
        <StandardHeader
          name="Welcome"
          parentName="Welcome"
          link="welcome"
        />
        <Container className="mt-6 mb-8" fluid>
            <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-dark">
                    Welcome Message
                  </h2>
                  <p className="lead text-dark">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Col>
                <div className="col">
              <div className="card-wrapper">
                <ListUsers
                  list={this.state.listUsers}
                  info={this.state.data.company_name}
                  update={this.handleUpdateUser}
                  delete={this.confirmAlertUser}
                />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Welcome;
