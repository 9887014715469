import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Alert,
  CardBody,
  FormText,
} from "reactstrap";
import config from "services/config";
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";
import { connect } from "react-redux";
import { getDataJobsSCM } from "./Redux/jobsSCMActions";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
class ListJobsSCM extends PureComponent {
  state = {
    actionListJobs: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      job_name: "",
      job_no: "",
    },

    alert: null,
    listJobs: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListJobs();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeString = (event) => {
    this.setState({
      actionListJobs: {
        ...this.state.actionListJobs,
        [event.target.name]: event.target.value,
      },
    });
  };

  getDataListJobs = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/jobs/list_jobs_scm", this.state.actionListJobs, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listJobs: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                this.props.dispatch(getDataJobsSCM(this.state.listJobs));
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = (data) => {
    this.props.history.push("/admin/job-scm-details/" + data.job_id);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListJobs: {
          ...this.state.actionListJobs,
          page: numPage,
        },
      },
      () => {
        this.getDataListJobs();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListJobs: {
          ...this.state.actionListJobs,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListJobs();
      }
    );
  };

  handletoAdd = () => {
    this.props.history.push("/admin/create-job-scm");
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.job_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.job_name}`} job, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Job has been deleted.
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListJobs: {
          ...this.state.actionListJobs,
          page: 1,
        },
      },
      () => {
        this.getDataListJobs();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListJobs: {
          page: 1,
          items_per_page: 10,
          order_by_field: "created_at",
          order_by_direction: "DESC",
          job_name: "",
          job_no: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListJobs();
            }
          );
        }
      }
    );
  };

  remove = (data) => {
    axios
      .post(
        config.API_URL + "/jobs/delete_job_scm",
        { job_id: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
          this.handleFilter();
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(err.response.data.message);
            }
          }
        }
      );
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListJobs.page) {
      toItem = this.state.total_items;
    } else {
      toItem = this.props.dataJobsSCM.length * this.state.actionListJobs.page;
    }
    if (this.state.actionListJobs.page === 1) {
      fromItem = 1;
    } else if (this.state.total_pages === this.state.actionListJobs.page) {
      fromItem = this.state.total_items - this.props.dataJobsSCM.length + 1;
    } else {
      fromItem =
        this.props.dataJobsSCM.length * (this.state.actionListJobs.page - 1) +
        1;
    }

    const availDataJobs = this.props.dataJobsSCM.map((jobs) => {
      return (
        <tr
          key={jobs.job_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(jobs)}
        >
          <td>{jobs.job_no}</td>
          <td>{jobs.job_name}</td>
          <td>{jobs.job_description}</td>
          <td>{moment(jobs.created_at).format('lll')}</td>
          <td>{moment(jobs.updated_at).format('lll')}</td>

          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.handleDetails(jobs)}>
                  Details
                </DropdownItem>
                <DropdownItem onClick={() => this.confirmAlert(jobs)}>
                  Remove
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });

    return (
      <>
        <StandardHeader
          name="Jobs"
          parentName="Jobs"
          link="jobs-scm"
        />
        <Container className="mt--6" fluid>
          <Row className="mb-4">
            <Col>
              <Button
                color="success"
                type="button"
                className="btn-icon"
                onClick={() => this.handletoAdd()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                Create Job
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Jobs</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Job Name</label>
                        <Input
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="job_name"
                          placeholder="Job Name"
                          value={this.state.actionListJobs.job_name}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Job No</label>
                        <Input
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="job_no"
                          placeholder="Job No"
                          value={this.state.actionListJobs.job_no}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">List Jobs</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.props.dataJobsSCM.length < 5 ? (
                      <div style={{ height: 323 }}>
                        {this.props.dataJobsSCM.length === 0 ? (
                          <Alert color="secondary">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Job No</th>
                                  <th scope="col">Job Name</th>
                                  <th scope="col">Job Description</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Updated At</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>{availDataJobs}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Job No</th>
                              <th scope="col">Job Name</th>
                              <th scope="col">Job Description</th>
                              <th scope="col">Created At</th>
                              <th scope="col">Updated At</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>{availDataJobs}</tbody>
                        </Table>
                      </>
                    )}
                  </div>

                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListJobs.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataJobsSCM: state.jobs_scm.dataJobsSCM,
  };
};

export default connect(mapStateToProps)(ListJobsSCM);
