import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import config from "services/config";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListPo = (props) => {
  const info = props.info;
  const actionsFile = (cell, row) => {
    var a = moment(new Date());//now
    var b = moment(row.after_close_po);

    if(cell === null || cell === ''){
      return 'Empty'
    } else {
      if (b.diff(a, 'days') < 0) {
        return 'Closed'
      } else {
        return (
          <Button
            className="btn-neutral text-uppercase"
            size="sm"
            type="button"
            onClick={() =>
              window.open(config.BUCKET_URL  + cell, "_blank")
            }
          >
            <span className="btn-inner--text ml-2">Download PDF</span>
          </Button>
        );
      }
    }
  };
  const actionsDate = (cell, row) => {
    return moment(cell).format("LL");
  };
  const actionDateTime = (cell, row) => {
    return moment(cell).format("lll");
  };
  const actionsFormatter = (cell, row) => {
    var a = moment(new Date());//now
    var b = moment(row.after_close_po);
    if (b.diff(a, 'days') < 0) {
      return 'Closed'
    } else {

      return (
        <>
          <a
            className="table-action"
            id={"tooltip12312"}
            style={{ cursor: "pointer" }}
            onClick={() => props.update(row)}
          >
            <i className="fas fa-user-edit" />
          </a>
          <UncontrolledTooltip delay={0} target={"tooltip12312"}>
            Update
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            id={"tooltip12313"}
            style={{ cursor: "pointer" }}
            onClick={() => props.delete(row)}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target={"tooltip12313"}>
            Remove
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            id={"tooltip12314"}
            style={{ cursor: "pointer" }}
            onClick={() => props.details(row)}
          >
            <i className="fa fa-arrow-right" />
          </a>
          <UncontrolledTooltip delay={0} target={"tooltip12314"}>
            Details
          </UncontrolledTooltip>
        </>
      );
    }
  };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="po_id"
          columns={[
            {
              dataField: "po_no",
              text: "No",
              sort: true,
            },
            {
              dataField: "company_name",
              text: "Company",
              sort: true,
            },
            {
              dataField: "po_date",
              text: "Date",
              sort: true,
              formatter: actionsDate,
            },
            {
              dataField: "po_delivery_date",
              text: "Delivery Date",
              sort: true,
              formatter: actionsDate,
            },
            {
              dataField: "created_at",
              text: "Created At",
              sort: true,
              formatter: actionDateTime,
            },
            {
              dataField: "updated_at",
              text: "Updated At",
              sort: true,
              formatter: actionDateTime,
            },
            {
              dataField: "po_file",
              text: "File",
              formatter: actionsFile,
            },
            {
              text: "Actions",
              formatter: actionsFormatter,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <h3 className="mb-0">List Po - {info} </h3>
                <p className="text-sm mb-0"> </p>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ListPo;
