import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import config from "services/config";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  Button,
  UncontrolledTooltip,
  Col,
  Row,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ListPccPurchaserVendor = (props) => {
  const info = props.info;
  const toggle = props.toggleModal;
  const isClosed = props.close;
  const actionsFile = (cell, row) => {
    if (cell === null || cell === '') {
      return 'Empty'
    } else {
      return (
        <Button
          className="btn-neutral text-uppercase"
          size="sm"
          type="button"
          onClick={() =>
            window.open(config.BUCKET_URL  + cell, "_blank")
          }
        >
          <span className="btn-inner--text ml-2">View File</span>
        </Button>
      );
    }
  };
  const actionsFileLink = (cell, row) => {
    if (cell === null || cell === '') {
      return 'Empty'
    } else {
      return (
        <Button
          className="btn-neutral text-uppercase"
          size="sm"
          type="button"
          onClick={() =>
            window.open(cell, "_blank")
          }
        >
          <span className="btn-inner--text ml-2">View File</span>
        </Button>
      );
    }
  };
  const actionDateTime = (cell, row) => {
    return moment(cell).format("lll");
  };
  
  return (
    <>
      <Card>
      {isClosed === 1 ?
          (
            <ToolkitProvider
            data={props.list}
            keyField="document_id"
            columns={[
              {
                dataField: "document_no",
                text: "Doc No",
                sort: true,
              },
              {
                dataField: "document_name",
                text: "Doc Category",
                sort: true,
              },
              {
                dataField: "document_description",
                text: "Doc Details",
                sort: true,
              },
              {
                dataField: "document_rev",
                text: "Version",
                sort: true,
              },
              {
                dataField: "created_at",
                text: "Submited At",
                sort: true,
                formatter: actionDateTime,
              },
              {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: actionDateTime,
              },
             
            ]}
          >
            {(props) => (
              <div>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">{info}</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                    </Col>
                  </Row>
                </CardHeader>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                  wrapperClasses="table-responsive"
                  striped
                  noDataIndication={() => (
                    <div style={{ height: 300 }}>
                      <Alert style={{ marginLeft: -30 }} color="secondary">
                        <strong>Data not found!</strong> Please check again!
                      </Alert>
                    </div>
                  )}
                />
              </div>
            )}
          </ToolkitProvider>
          ):
          (
            <ToolkitProvider
              data={props.list}
              keyField="document_id"
              columns={[
                {
                  dataField: "document_no",
                  text: "Doc No",
                  sort: true,
                },
                {
                  dataField: "document_name",
                  text: "Doc Category",
                  sort: true,
                },
                {
                  dataField: "document_description",
                  text: "Doc Details",
                  sort: true,
                },
                {
                  dataField: "document_rev",
                  text: "Version",
                  sort: true,
                },
                {
                  dataField: "created_at",
                  text: "Submited At",
                  sort: true,
                  formatter: actionDateTime,
                },
                {
                  dataField: "updated_at",
                  text: "Updated At",
                  sort: true,
                  formatter: actionDateTime,
                },
                {
                  dataField: "document_file",
                  text: "File",
                  formatter: actionsFile,
                },
                {
                  dataField: "document_file_link",
                  text: "Link File",
                  formatter: actionsFileLink,
                },
              ]}
            >
              {(props) => (
                <div>
                  <CardHeader>
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">{info}</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                      </Col>
                    </Row>
                  </CardHeader>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                    wrapperClasses="table-responsive"
                    striped
                    noDataIndication={() => (
                      <div style={{ height: 300 }}>
                        <Alert style={{ marginLeft: -30 }} color="secondary">
                          <strong>Data not found!</strong> Please check again!
                        </Alert>
                      </div>
                    )}
                  />
                </div>
              )}
            </ToolkitProvider>
          )
        }
      </Card>
    </>
  );
};

export default ListPccPurchaserVendor;
