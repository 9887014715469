
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="bg-dark py-5" id="footer-main">
          <Container>
            <Row>
              <Col className="col-lg-3 col-md-3 col-sm-6" xs="12" sm="9">
                <h2 className="text-white">Company</h2>
                <ul className="list-unstyled ">
                <li><a className="text-white" href="https://www.ptssb.co.id/vision-mission" target="_blank">About Us</a></li>
                  <li><a className="text-white" href="https://www.ptssb.co.id/our-business" target="_blank">Our Bussiness</a></li>
                  <li><a className="text-white" href="https://www.ptssb.co.id/news-events" target="_blank">News & Events</a></li>
                  <li><a className="text-white" href="https://www.ptssb.co.id/career" target="_blank">Career</a></li>
                </ul>
              </Col>
              <Col className="col-lg-3 col-md-3 col-sm-6" xs="12" sm="9">
                <h2 className="text-white">Help and Support</h2>
                <ul className="list-unstyled ">
                  <li><a className="text-white" href="https://www.ptssb.co.id/contact-us" target="_blank">Contact Us</a></li>
                </ul>
              </Col>
              <Col  className="col-lg-3 col-md-3 col-sm-6" xs="12" sm="9">
                <h2 className="text-white">Legal</h2>
                <ul className="list-unstyled ">
                  <li><a className="text-white" href="/#" target="_blank">Terms & Conditions</a></li>
                  <li><a className="text-white" href="/#" target="_blank">Privacy Policy</a></li>
                </ul>
              </Col>
              <Col  className="col-lg-3 col-md-3 col-sm-6" xs="12" sm="9">
                <h2 className="text-white">Head Office</h2>
                <p className="text-white">Gedung TMT I, 5th Floor, Suite 501
                    Jl. Cilandak KKO No. 1
                    Jakarta 12560</p>
                <ul className="list-unstyled ">
                  <li><a className="text-white">Phone: 021-29976830</a></li>
                  <li><a className="text-white">Fax: 021-29976835</a></li>
                </ul>
                <Button
                    className="btn-icon-only"
                    color="facebook"
                    type="button"
                    href="https://web.facebook.com/sanggarsaranabaja"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fab fa-facebook" />
                    </span>
                  </Button>
                  <Button
                    className="btn-icon-only"
                    style={{color: "#0a66c2"}}
                    type="button"
                    href="https://www.linkedin.com/company/pt-sanggar-sarana-baja/mycompany/"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fab fa-linkedin" />
                    </span>
                  </Button>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
