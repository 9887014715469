import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  // Card,
  // CardHeader
} from "reactstrap";
//services
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
// import moment from "moment";

//packages
// import Pagination from "react-bootstrap-pagination-logic";

//redux
// import { connect } from "react-redux";
// import { getDataPoVendor } from "./Redux/poVendorActions";
// import moment from "moment";
// react component used to create sweet alerts
// import SweetAlert from "react-bootstrap-sweetalert";
// import StandardHeader from "components/Headers/Breadcrumb";
import Spacer from "components/Headers/Spacer";
import ListPOItem from "./TabPoItem";
// class ListPo extends PureComponent {
class ListPo extends Component {
  state = {
    taskOA: false,
    dataOA: [],
    dataPoItem: [],
    isLoading: false,
    alert: null,
    checked: false,
    actionFilter: {
      po: this.props.match.params.idPO,
      search: "",
      table: "1",
      invoice: "",
    },
    OAid: "",
    OA: [],
    ver_id: "",
    po: "",
    po_item_no: "",
    data: {
      id: "",
      po: "",
      po_item_no: "",
      track_no: "",
      est_date: "",
      qty: "",
      ref: "",
      reject: ""
    },
    temp: {
      est_date: "",
      qty: "",
    },
  };

  //   componentDidmount() {
  //   console.log("cek");
  //   this.mounted = true;
  //   this.getListUsers();

  //   axios.get(`https://jsonplaceholder.typicode.com/users`)
  //   .then(res => {
  //     console.log(res.data)
  //     // this.setState({ person: persons });
  //   })
  // }
  componentDidMount = async () => {
    this.mounted = true;
    // this.getlistUsers();
    // this.getlistUsersVendor();
    // axios.get(`https://prod-08.southeastasia.logic.azure.com:443/workflows/e52584b2d80b41e799c1877563462885/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=65H2dZuLetPrBA__uKBUHW2Nwbb243tWuyR1du94oto`)
    // // axios.get(`https://jsonplaceholder.typicode.com/users`)
    // .then(res => {
    //   // console.log(res.data)
    //   // const persons = res.data;
    //   this.setState({ person: res.data });
    // //   const persons = res.data;
    //   // this.setState({ listUsersVendor : res.data });
    // })
    this.getListPO();
    // this.postOA();
    this.handleToggle = this.handleToggle.bind(this);
  };

  handleToggle(e) {
    let isChecked = e.target.checked;
    if (isChecked === true) {
      this.setState(
        {
          data: {
            ...this.state.data,
            [e.target.name]: 1,
          },
        },
        () => this.confirmAlert()
      );
    } else {
      this.setState(
        {
          data: {
            ...this.state.data,
            [e.target.name]: 0,
          },
        },
        () => console.log(this.state.data)
      );
    }
  }
  getListPO = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/po/list_po_line_item", this.state.actionFilter, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                dataPoItem: res.data.data,
                // countTable1: res.data.data.length,
              }
              // () => {
              //   if (this.state.isFilterLoading) {
              //     this.setState({
              //       isFilterLoading: false,
              //       isFilter: true,
              //     });
              //   }
              //   if (this.state.isResetLoading) {
              //     this.setState({
              //       isResetLoading: false,
              //       isFilter: true,
              //     });
              //   }
              // }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  // getOA = () => {
  //   // if (this.state.isResetLoading === false) {
  //   //   if (this.state.isFilterLoading === false) {
  //   //     this.setState({
  //   //       isFilterLoading: true,
  //   //     });
  //   //   }
  //   // }
  //   axios
  //     .post(config.API_URL + "/po/list_po", this.state.OAid, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: config.TOKEN,
  //       },
  //     })
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState(
  //             {
  //               po: res.data.data[0].po,
  //               po_item_no: res.data.data[0].po_item_no,
  //               track_no: res.data.data[0].tracking_no,
  //               material_short_text: res.data.data[0].material_short_text,
  //               est_date: res.data.data[0].date_expected,
  //               qty: res.data.data[0].quantity,
  //               // countTable1: res.data.data.length,
  //             }
  //             // () => {
  //             //   if (this.state.isFilterLoading) {
  //             //     this.setState({
  //             //       isFilterLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             //   if (this.state.isResetLoading) {
  //             //     this.setState({
  //             //       isResetLoading: false,
  //             //       isFilter: true,
  //             //     });
  //             //   }
  //             // }
  //           );
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };

  formatDate = (input) =>{
    var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1], 
    day = datePart[2];
  
    return day+'.'+month+'.'+year;
  }
  postOA = () => {
    var new_est_date = this.formatDate(this.state.data.est_date)
    // var setDate = this.state.data.est_date
    // var sapDate = (setDate.getDate()) +"."+ (setDate.getMonth()+1) +"."+ (setDate.getFullYear())
    this.setState({ 
      isLoading: true ,
    })
    // console.log(this.state.data)
    axios
      .post(config.API_URL + "/po/form_order_acknowledge", {
        id: this.state.data.id,
        po: this.state.data.po,
        track_no: this.state.data.track_no,
        poitem: this.state.data.poitem,
        qty: this.state.data.qty,
        est_date: new_est_date,
        ref: this.state.data.ref,
        reject: this.state.data.reject
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                taskOA: !this.state.taskOA,
                isLoading: false,
              },
              () => this.successAlert(res.data.message)
            );
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(
                this.state.filledDataTaskFeedAssign.task_feed_description
              );
              this.setState({ isLoading: false });
            }
          }
        }
      );
  };
  handleAlertFormOA = () => {
    this.setState(
      {
        alert: null,
      },
      () => this.getListPO()
    );
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.handleAlertFormOA()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
          timeout={2000}
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  confirmAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          showCloseButton
          style={{ display: "block" }}
          title="Confirmation"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="dark"
          confirmBtnText="Yes, Reject"
          cancelBtnBsStyle="link"
          cancelBtnText="Cancel"
          btnSize=""
          timeout={5000}
        >
          "Order Acknowledge will be rejected ?"
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/companies/view_vendor",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleOA = (e) => {
    this.setState({
      data: {
        id: e.id,
        material: e.material,
        material_short_text: e.materialname,
        po: e.po,
        poitem: e.poitem,
        est_date: e.dateexpected.substr(0, 10),
        ref:"",
        track_no: e.trackingno,
        reject: 0
      },
      temp: {
        qty: e.quantity,
        est_date: e.dateexpected.substr(0, 10),
      },
      taskOA: !this.state.taskOA,
    });
  };
  toggleModal = () => {
    this.setState({
      data: {
        id: "",
        po: "",
        poitem: "",
        track_no: "",
        est_date: "",
        material: "",
        material_short_text: "",
        qty: "",
        ref: "",
        reject: 0,
      },
      taskOA: !this.state.taskOA,
    });
  };
  handleUpdate = (e) => {
    console.log(e);
  };

  handleStringOA = (e) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value,
        },
      },
      () => console.log(this.state.data)
    );
  };
  handleChangeString = (event) => {
    this.setState(
      {
        actionFilter: {
          ...this.state.actionFilter,
          [event.target.name]: event.target.value,
        },
      },
      () => {
        this.getListPO();
      }
    );
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleListOD = (l) => {
    this.props.history.push("/vendor/order-delivery/" + l.po);
  };
  handleOD = (l) => {
    this.props.history.push("/vendor/order-delivery-form/" + l.id);
  };
  handleGR = (l) => {
    this.props.history.push("/vendor/good-receive/" + l.po);
  };
  render() {
    var po = this.state.actionFilter.po;
    return (
      <div>
        <Col sm="6">
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.taskOA}
            toggle={() => this.toggleModal("taskOA")}
          >
            <div className="modal-body">
              <Row>
                {/* <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          disabled
                          value={
                            this.state.filledTaskFeed.task_feed_description
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          value={moment(
                            this.state.filledTaskFeed.start_date
                          ).format("YYYY-MM-DD")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          disabled
                          value={moment(
                            this.state.filledTaskFeed.due_date
                          ).format("YYYY-MM-DD")}
                        />
                      </FormGroup>
                    </Col> */}

                <Col xs="12">
                  <Row>
                    <Col xs="10">
                      <FormGroup>
                        <h6 className="modal-title" id="modal-title-default">
                          {this.state.data.po}
                          {" | "}
                          {this.state.data.poitem}
                        </h6>
                        <small className="text-muted">
                          Order acknowledge PO
                        </small>{" "}
                        <br />
                      </FormGroup>
                    </Col>
                    <Col xs="2">
                      <FormGroup> 
                        <button
                          aria-label="Close"
                          className="close text-right"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => this.toggleModal("taskOA")}
                        >
                          <span aria-hidden={true}>X</span>
                        </button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <hr />
                <Col xs="12">
                  <FormGroup>
                      <fieldset
                style={{
                  border: "1px solid #DEE2E6",
                  padding: "0.4em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >Product Number</legend>
                  <div style={{fontSize: "0.86", fontWeight:"400"}}>{this.state.data.material}</div>
                      </fieldset>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                      <fieldset
                style={{
                  border: "1px solid #DEE2E6",
                  padding: "0.4em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >Product Name</legend>
                  <div style={{fontSize: "0.86", fontWeight:"400"}}>{this.state.data.material_short_text}</div>
                      </fieldset>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                      <fieldset
                style={{
                  border: "1px solid #DEE2E6",
                  padding: "0.87em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >Quantity</legend>
                  <div style={{fontSize: "0.5", fontWeight:"400"}}>{this.state.temp.qty}</div>
                      </fieldset>
                  </FormGroup>
                </Col>
                <Col xs="6">
                <FormGroup>
                      <fieldset
                style={{
                  border: "1px solid #DEE2E6",
                  padding: "0.87em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="w-auto mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >      Estimate date{" "}</legend>
                  <div style={{fontSize: "0.5", fontWeight:"400"}}>{this.state.temp.est_date}</div>
                      </fieldset>
                  </FormGroup>
                 
                </Col>
                <Col xs="6">
                <FormGroup>
                  <fieldset
                style={{
                  padding: "0.4em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >Conf Quantity</legend>
                  <Input
                      type="number"
                      min="1"
                      name="qty"
                      placeholder={this.state.temp.qty}
                      onChange={this.handleStringOA}
                      value={this.state.data.qty}

                    />
                      </fieldset>
                   
                  </FormGroup>
                </Col>
                <Col xs="6">
                  
                  <FormGroup>
                  <fieldset
                style={{
                  padding: "0.4em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className="mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >   Conf Estimate Date{" "}
                <small className="text-muted">mm/dd/yy</small></legend>
                  <Input
                      type="date"
                      name="est_date"
                      onChange={this.handleStringOA}
                      value={this.state.data.est_date}
                    />
                      </fieldset>
                   
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                  <fieldset
                style={{
                  // border: "1px solid",
                  padding: "0.4em",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <legend
                  className=" mb-0"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >   Reference{" "}</legend>
                  <Input
                      type="textarea"
                      name="ref"
                      onChange={this.handleStringOA}
                      maxLength="35"
                      value={this.state.data.ref}
                    />
                      </fieldset>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Row>
                    <Col xs="7">
                      {" "}
                      <label className="form-control-label">Reject</label>
                    </Col>
                    <Col xs="5" className="text-right">
                      <FormGroup>
                        <label className="custom-toggle custom-toggle-dark mr-1">
                          <Input
                            type="checkbox"
                            name="reject"
                            value={this.state.data.reject}
                            onChange={(e) => this.handleToggle(e)}
                            checked={this.state.data.reject[this.state.data]}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <small className="text-muted">
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        />{" "}
                        This order acknowledge item verification will only be
                        carried out once and cannot be changed.
                      </small>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="modal-footer" style={{ marginTop: "-2em" }}>
              <Button
                className="text-center"
                color="link"
                data-dismiss="modal"
                type="button"
                block
                onClick={() => this.toggleModal("taskOA")}
              >
                Cancel
              </Button>
              <Button
                className="text-center"
                color="success"
                type="button"
                block
                onClick={() => this.postOA()}
              >
                {this.state.isLoading ? (
                  <>
                    <span className="btn-inner--text">
                      <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                      Submit
                    </span>
                  </>
                ) : (
                  <span className="btn-inner--text">Submit</span>
                )}
              </Button>
            </div>
          </Modal>
        </Col>
        <Spacer />
        <Container fluid>
          <div className="card-wrapper">
            <ListPOItem
              list={this.state.dataPoItem}
              tab={
                <a
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    fontWeight: "bolder",
                    fontSize: "0.875rem",
                    color: "#5E72E4",
                  }}
                  onClick={() => {
                    this.props.history.push("/vendor/purchase-order");
                  }}
                >
                  Purchase Order - {po}
                </a>
              }
              info={
                <InputGroup size="sm">
                  <Input
                    bsSize="sm"
                    type="search"
                    // autofocus="autofocus"
                    className="search"
                    id="search"
                    placeholder="Search"
                    result
                    onKeyPress={this.keyPressed}
                    value={this.state.actionFilter.search}
                    onChange={this.handleChangeString}
                  />
                </InputGroup>
              }
              update={this.handleUpdate}
              oa={this.handleOA}
              listod={this.handleListOD}
              od={this.handleOD}
              gr={this.handleGR}
            />
          </div>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default ListPo;
