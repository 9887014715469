/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

// reactstrap components
import {
  Card,
  CardHeader,
  Alert,
  UncontrolledTooltip,
  Button,
  Row,
  Col,
  Progress,
} from "reactstrap";
import moment from "moment";
// const { ExportCSVButton } = CSVExport;
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      {/* <button className="btn btn-success" onClick={ handleClick }>Export to CSV</button> */}
      <a onClick={handleClick} style={{ cursor: "pointer" }}>
        <i
          className="fa fa-arrow-circle-down text-info"
          id={"do"}
          aria-hidden="true"
        ></i>
      </a>
      <UncontrolledTooltip delay={0} target={"do"}>
        Download
      </UncontrolledTooltip>
    </div>
  );
};
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const TapPoItem = (props) => {
  const tab = props.tab;
  const info = props.info;
  // const actionsRole = (cell, row) => {
  //    if(cell === 1) return 'SCM';
  //    if(cell === 2) return 'Vendor';
  //   }
  //   const actionsIsActive = (cell, row) => {
  //     if(cell === 1) return 'Yes';
  //     if(cell === 0) return 'No';
  //    }
  // const handleDate =(cell) =>{
  //   return (cell.substr(0,10))
  // }
  return (
    <>
      <Card>
        <ToolkitProvider
          exportCSV={{
            // fileName: 'purchasorderitem.csv',
            separator: "|",
            // ignoreHeader: true,
            noAutoBOM: false,
            blobType: "text/csv;charset=ansi",
          }}
          hover
          data={props.list}
          keyField="id"
          columns={[
            {
              dataField: "oastatus",
              text: "Order Acknowledge",
              sort: true,
              headerAlign:"center",
              align:"center",
              formatter: (cell, row) => {
                return (
                      <>
                        <a
                          className="table-action"
                          id={"tooltip12312"}
                          style={{ cursor: "pointer" }}
                          onClick={() => props.oa(row)}
                        >
                          <span style={{ color: "#F7B217" }}>●</span> Confirmation
                        </a>
                      </>
                    );
                // if (cell === "Pending") {
                //   return (
                //     <>
                //       <a
                //         className="table-action"
                //         id={"tooltip12312"}
                //         style={{ cursor: "pointer" }}
                //         onClick={() => props.oa(row)}
                //       >
                //         <span style={{ color: "#8D7B68" }}>●</span> Confirmation
                //       </a>
                //     </>
                //   );
                // } else if (cell === "Accepted") {
                //   return (
                //     <>
                //       <a
                //         className="table-action"
                //         id={"oa2"}
                //         style={{ cursor: "pointer" }}
                //         onClick={() => props.oa(row)}
                //       >
                //         <span style={{ color: "#4E6E81" }}>●</span> Accept
                //       </a>
                //       <UncontrolledTooltip delay={0} target={"oa2"}>
                //         Item has been confirmed Accepted
                //       </UncontrolledTooltip>
                //     </>
                //   );
                // } else if (cell === "Declined") {
                //   return (
                //     <>
                //       <a
                //         className="table-action"
                //         id={"tooltip12312"}
                //         style={{ cursor: "pointer" }}
                //         onClick={() => props.oa(row)}
                //       >
                //         <span style={{ color: "#F5365C" }}>●</span> Reject
                //       </a>
                //       <UncontrolledTooltip delay={0} target={"tooltip12312"}>
                //         Item has been confirmed Rejected
                //       </UncontrolledTooltip>
                //     </>
                //   );
                // } else {
                //   return null;
                // }
              },
            },
            {
              dataField: "trackingno",
              text: "TRACKING NO",
              hidden: true,
            },
            {
              dataField: "poitem",
              text: "ITEM",
              sort: true,
              align: "center",
            },
            {
              dataField: "material",
              text: "PRODUCT NUMBER",
              sort: true,
            },
            {
              dataField: "materialname",
              text: "PRODUCT NAME",
              sort: true,
            },
            {
              dataField: "unit",
              text: "UNIT",
              sort: true,
              headerAlign:"center",
              align:"center"
            },
            {
              dataField: "unitcost",
              text: "UNIT PRICE",
              sort: true,
              headerAlign:"center",
              align:"right"
            },
            {
              dataField: "totalcost",
              text: "TOTAL PRICE",
              sort: true,
              headerAlign:"center",
              align:"right"
            },
            {
              dataField: "currency",
              text: "CURRENCY",
              sort: true,
              headerAlign:"center",
              align:"center"
            },
            // {
            //   dataField: "exchange_rate",
            //   text: "EXCHANGE RATE",
            //   sort: true,
            // },
            // {
            //   dataField: "oa_status",
            //   text: "OA STATUS",
            //   sort: true,
            // },
            {
              dataField: "quantity",
              text: "QUANTITY",
              sort: true,
              align: "center",
              formatter: (cell, row) => {
                return (
                  <>
                    <a
                      style={{ cursor: "pointer", color: "DodgerBlue" }}
                      className="font-weight-bold"
                      onClick={() => props.listod(row)}
                    >
                      {cell}
                    </a>
                  </>
                );
                // if (row.oastatus === "Accepted") {
                //   return (
                //     <>
                //       <a
                //         style={{ cursor: "pointer", color: "DodgerBlue" }}
                //         className="font-weight-bold"
                //         onClick={() => props.listod(row)}
                //       >
                //         {cell}
                //       </a>
                //     </>
                //   );
                // } else {
                //   return cell;
                // }
              },
            },
            {
              dataField: "outstandinggr",
              text: "QUANTITY AWAITING",
              sort: true,
              align: "center",
              formatter: (cell, row) => {
                return (
                  <>
                    <a
                      style={{ cursor: "pointer", color: "DodgerBlue" }}
                      className="font-weight-bold"
                      onClick={() => props.od(row)}
                    >
                      {cell}
                    </a>
                  </>
                );
                // if (row.oastatus === "Accepted") {
                //   return (
                //     <>
                //       <a
                //         style={{ cursor: "pointer", color: "DodgerBlue" }}
                //         className="font-weight-bold"
                //         onClick={() => props.od(row)}
                //       >
                //         {cell}
                //       </a>
                //     </>
                //   );
                // } else {
                //   return cell;
                // }
              },
            },
            {
              dataField: "shippedqty",
              text: "QUANTITY SEND",
              sort: true,
              align: "center",
              formatter: (cell, row) => {
                if (row.oastatus === "Accepted") {
                  return (
                    <>
                      <a
                        style={{ cursor: "pointer", color: "DodgerBlue" }}
                        className="font-weight-bold"
                        onClick={() => props.gr(row)}
                      >
                        {cell}
                      </a>
                    </>
                  );
                } else {
                  return cell;
                }
              },
            },
            {
              dataField: "shippedqty",
              text: "COMPLETION",
              headerAlign: "center",
              sort: true,
              formatter: (cell, row) => {
                var percent = (cell * 100) / row.quantity;
                if (percent ===100) {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        <div className="mr-2">{percent + "%"}</div>
                        <Progress
                          style={{
                            height: "15px",
                          }}
                          max={row.quantity}
                          value={cell}
                          color="success"
                        />
                      </div>
                    </>
                  );
                } else if (percent >= 25 && percent <= 99) {
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        <div className="mr-2">{percent + "%"}</div>
                        <Progress
                          style={{
                            height: "15px",
                          }}
                          max={row.quantity}
                          value={cell}
                          color="warning"
                        />
                      </div>
                    </>
                  );
                } else if (percent < 24){
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        <div className="mr-2">{percent + "%"}</div>
                        <Progress
                          style={{
                            height: "15px",
                          }}
                          max={row.quantity}
                          value={cell}
                          color="danger"
                        />
                      </div>
                    </>
                  );
                }
              },
            },
            {
              dataField: "dateexpected",
              text: "DATE EXPECTED",
              headerAlign:"center",
              align:"center",
              sort: true,
              formatter: (cell) => {
                if (cell === undefined) {
                  return null;
                } else {
                  return moment(cell).format("DD-MM-YYYY");
                }
              },
            },
          
            
            // {
            //   dataField: "itemstatus",
            //   text: "ITEM STATUS",
            //   sort: true,
            //   formatter: (cell, row) => {
            //     if (cell === "Canceled") {
            //       return (
            //         <>
            //         <a
            //             className="d-block"
            //             style={{
            //               background: "#F5365C",
            //               color: "white",
            //               textAlign: "center",
            //               padding: "2px",
            //               borderRadius: "120px",
            //             }}
            //           >
            //               <i className="fa fa-times-circle" aria-hidden="true"/>{" "}
            //             Reject
            //           </a>
            //         </>
            //       );
            //     } else if (cell === "Received") {
            //       return (
            //         <>
            //          <a
            //             className="d-block"
            //             style={{
            //               background: "#4E6E81",
            //               color: "white",
            //               textAlign: "center",
            //               padding: "2px",
            //               borderRadius: "120px",
            //             }}
            //           >
            //               <i className="fa fa-check-circle" aria-hidden="true"/>{" "}
            //             Receive
            //           </a>
            //         </>
            //       );
            //     } else {
            //       return (
            //         <>
            //           <a
            //             className="d-block"
            //             style={{
            //               background: "#8D7B68",
            //               color: "white",
            //               textAlign: "center",
            //               padding: "2px",
            //               borderRadius: "120px",
            //             }}
            //           >
            //             <i className="fa fa-circle" aria-hidden="true"/>{" "}
            //             Pending
            //           </a>
            //         </>
            //       );
            //     }
            //   },
            // },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col className=" col-lg-6 col-md-6  col-sm-6  col-12 ">
                    {tab}
                  </Col>
                  <Col className="col-lg-6 col-md-6  col-sm-6  col-12 ml-auto">
                    <div className="d-flex justify-content-end">
                      <div style={{width: "20em"}}>{info}</div>
                      <div className="pl-2">
                        <MyExportCSV {...props.csvProps}>export</MyExportCSV>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                hover
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>No data record</strong>
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TapPoItem;
