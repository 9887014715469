import React, { PureComponent } from "react";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Modal,
  Label
} from "reactstrap";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import PropTypes from "prop-types";
import ListDocumentsPurchaserVendor from "./ListDocumentsPurchaserVendor";
import { validateDocument } from "services/validate";
import ListDocumentsSupplierVendor from "./ListDocumentsSupplierVendor";
class DocumentsVendor extends PureComponent {
  state = {
    dataSupplier: {
      po_id: 0,
      document_no: "",
      document_name: "",
      document_type: "Document",
      document_description: "",
      document_file: "",
      document_owner: "supplier",
      document_rev: 0,
      send_email: false,
    },
    dataSupplierUpdate: {
      po_id: 0,
      company_id: 0,
      job_id: 0,
      document_no: "",
      document_name: "",
      document_type: "Document",
      document_description: "",
      document_file: "",
      document_owner: "supplier",
      document_rev: 0,
    },
    actionListDocumentPurchaser: {
      po_id: this.props.data.po_id,
      document_type: 'Document',
      document_owner: "purchaser",
    },
    actionListDocumentSupplier: {
      po_id: this.props.data.po_id,
      document_type: 'Document',
      document_owner: "supplier",
    },
    close_po: 0,
    sizeFile: false,
    sizeFileUpdate: false,
    listDocumentPurchaser: [],
    listDocumentSupplier: [],
    suppllierModal: false,
    suppllierModalUpdate: false,
    isLoading: false,
    alert: null,
    paramErrorSupplier: {},
    paramErrorSupplierUpdate: {},
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount = async () => {
    this.mounted = true;
    this.handleGetListDocumentsPurchaser();
    this.handleGetListDocumentsSupplier();
    this.handleGetStatusPO();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleGetStatusPO = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/po/po_status",
        { po_id: this.props.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        close_po: Number(response.data.data[0].close_po),
      }, () => {
        console.log(this.state.close_po)
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleGetListDocumentsPurchaser = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/documents/list_documents_scm",
        { ...this.state.actionListDocumentPurchaser, po_id: this.props.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        listDocumentPurchaser: response.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleGetListDocumentsSupplier = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/documents/list_po_vendor",
        { ...this.state.actionListDocumentSupplier, po_id: this.props.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        listDocumentSupplier: response.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  onFileChangeSupplier = (e) => {
    var file = e.target.files[0];
    // if (file.type === "application/pdf") {
    //   if (file.size < 40960000) {
    //     this.setState({
    //       dataSupplier: {
    //         ...this.state.dataSupplier,
    //         document_file: file,
    //       },
    //     });
    //   } else {
    //     this.failedAlert("File size max 5mb");
    //   }
    // } else {
    //   this.failedAlert("File format must be in *.pdf");
    // }
    this.setState({
      dataSupplier: {
        ...this.state.dataSupplier,
        document_file: file,
      },
    });
  };

  onFileChangeSupplierUpdate = (e) => {
    var file = e.target.files[0];
    // if (file.type === "application/pdf") {
    //   if (file.size < 40960000) {
    //     this.setState({
    //       dataSupplierUpdate: {
    //         ...this.state.dataSupplierUpdate,
    //         document_file: file,
    //       },
    //     });
    //   } else {
    //     this.failedAlert("File size max 5mb");
    //   }
    // } else {
    //   this.failedAlert("File format must be in *.pdf");
    // }
    this.setState({
      dataSupplierUpdate: {
        ...this.state.dataSupplierUpdate,
        document_file: file,
      },
    });
  };

  handleSubmitSupplierUpdate = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let formData = new FormData();
        const paramErrorSupplierUpdate = validateDocument(
          this.state.dataSupplierUpdate
        );
        this.setState({ paramErrorSupplierUpdate });
        formData.append(
          "document_id",
          this.state.dataSupplierUpdate.document_id
        );
        formData.append("po_id", this.state.dataSupplierUpdate.po_id);
        formData.append("user_id", config.USER_ID);
        formData.append("company_id", this.state.dataSupplierUpdate.company_id);
        formData.append("job_id", this.state.dataSupplierUpdate.job_id);
        formData.append(
          "document_no",
          this.state.dataSupplierUpdate.document_no
        );
        formData.append(
          "document_owner",
          this.state.dataSupplierUpdate.document_owner
        );
        formData.append(
          "document_name",
          this.state.dataSupplierUpdate.document_name
        );
        formData.append(
          "document_type",
          this.state.dataSupplierUpdate.document_type
        );
        formData.append(
          "document_description",
          this.state.dataSupplierUpdate.document_description
        );
        formData.append(
          "document_file",
          this.state.dataSupplierUpdate.document_file
        );
        formData.append(
          "document_file_link",
          this.state.dataSupplierUpdate.document_file_link
        );
        formData.append(
          "document_rev",
          this.state.dataSupplierUpdate.document_rev
        );
        if (Object.keys(paramErrorSupplierUpdate).length === 0) {
          axios
            .post(config.API_URL + "/documents/update_document_vendor", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                  });
                }
                this.handleGetListDocumentsSupplier();
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataSupplierUpdate.document_no);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  handleSubmitSupplier = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let formData = new FormData();
        const paramErrorSupplier = validateDocument(this.state.dataSupplier);
        this.setState({ paramErrorSupplier });
        formData.append("email", config.EMAIL);
        formData.append("po_id", this.props.data.po_id);
        formData.append("user_id", config.USER_ID);
        formData.append("company_id", this.props.data.company_id);
        formData.append("job_id", this.props.data.job_id);
        formData.append("document_no", this.state.dataSupplier.document_no);
        formData.append(
          "document_owner",
          this.state.dataSupplier.document_owner
        );
        formData.append("document_name", this.state.dataSupplier.document_name);
        formData.append("document_type", this.state.dataSupplier.document_type);
        formData.append(
          "document_description",
          this.state.dataSupplier.document_description
        );
        formData.append(
          "document_file_link",
          this.state.dataSupplier.document_file_link
        );
        formData.append("document_file", this.state.dataSupplier.document_file);
        formData.append("document_rev", this.state.dataSupplier.document_rev);
        formData.append("send_email", this.state.dataSupplier.send_email);
        if (Object.keys(paramErrorSupplier).length === 0) {
          axios
            .post(config.API_URL + "/documents/create_document_vendor", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataSupplier: {
                      po_id: this.props.id,
                      document_no: "",
                      document_name: "",
                      document_type: "Document",
                      document_description: "",
                      document_file: "",
                      document_owner: "supplier",
                      document_rev: 0,
                    },
                  });
                  this.handleGetListDocumentsSupplier();
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataPo.po_no);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleChangeStringSupplier = (event) => {
    this.setState({
      dataSupplier: {
        ...this.state.dataSupplier,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringSupplierUpdate = (event) => {
    this.setState({
      dataSupplierUpdate: {
        ...this.state.dataSupplierUpdate,
        [event.target.name]: event.target.value,
      },
    });
  };

  hanldeSupplierUpdate = async (data) => {
    if (data.document_file === null || data.document_file === '') {
      this.setState({
        supplierModalUpdate: true,
        sizeFileUpdate: true,
        dataSupplierUpdate: {
          document_id: data.document_id,
          po_id: data.po_id,
          company_id: data.company_id,
          job_id: data.job_id,
          document_no: data.document_no,
          document_name: data.document_name,
          document_type: data.document_type,
          document_description: data.document_description,
          document_file: null,
          document_file_link: data.document_file_link,
          document_owner: "supplier",
          document_rev: data.document_rev,
        },
      });
    } else {
      let response = await fetch(
        config.BUCKET_URL + data.document_file
      );
      let data_ = await response.blob();
      let metadata = {
        type: "file/pdf",
      };
      let file = await new File([data_], +data.document_no + ".pdf", metadata);
      this.setState({
        supplierModalUpdate: true,
        sizeFileUpdate: false,
        dataSupplierUpdate: {
          document_id: data.document_id,
          po_id: data.po_id,
          company_id: data.company_id,
          job_id: data.job_id,
          document_no: data.document_no,
          document_name: data.document_name,
          document_type: data.document_type,
          document_description: data.document_description,
          document_file: file,
          document_file_link: null,
          document_owner: "supplier",
          document_rev: data.document_rev,
        },
      });
    }
  };
  removeDocument = (data) => {
    axios
      .post(
        config.API_URL + "/documents/delete_document_scm",
        { document_id: data.document_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertDocument();
          this.handleGetListDocumentsSupplier();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  confirmAlertDocument = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeDocument(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.document_no}`} document, You won't be able to revert
          this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertDocument = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Document has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleSendEmail = (event) => {
    this.setState({
      dataSupplier: {
        ...this.state.dataSupplier,
        send_email: event.target.checked
      }
    })
  }

  handleSizeFile = (event) => {
    this.setState({
      sizeFile: event.target.checked,
      dataSupplier: {
        ...this.state.dataSupplier,
        document_file: null
      }
    })
  }
  handleSizeFileUpdate = (event) => {
    this.setState({
      sizeFileUpdate: event.target.checked,
      dataSupplierUpdate: {
        ...this.state.dataSupplierUpdate,
        document_file: null
      }
    })
  }
  render() {
    return (
      <>
        <Row>
          <Col sm="6">
            <ListDocumentsPurchaserVendor
              list={this.state.listDocumentPurchaser}
              close={this.state.close_po}
              info={"Purchaser"}
            />
          </Col>
          <Col sm="6">
            <ListDocumentsSupplierVendor
              list={this.state.listDocumentSupplier}
              info={"Supplier"}
              close={this.state.close_po}
              update={this.hanldeSupplierUpdate}
              delete={this.confirmAlertDocument}
              toggleModal={this.toggleModal}
            />
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.supplierModal}
              toggle={() => this.toggleModal("supplierModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Add Document (PO Bundle)
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("supplierModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">No</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="document_no"
                        placeholder="Document no"
                        onChange={this.handleChangeStringSupplier}
                        required
                        className={
                          this.state.paramErrorSupplier.document_no ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplier.document_no}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="document_name"
                        placeholder="Document name"
                        onChange={this.handleChangeStringSupplier}
                        required
                        className={
                          this.state.paramErrorSupplier.document_name
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplier.document_name}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={this.handleSizeFile} /> Link?
                      </Label>
                    </FormGroup>
                  </Col>
                  {this.state.sizeFile ?
                    (
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Link File</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="document_file_link"
                            placeholder="Link File"
                            onChange={this.handleChangeStringSupplier}
                            required
                            className={
                              this.state.paramErrorSupplier.document_file_link
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorSupplier.document_file_link}
                          </div>
                        </FormGroup>
                      </Col>
                    ) :
                    (
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <label className="form-control-label">File</label>
                        <Form>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="document_file"
                              onChange={this.onFileChangeSupplier}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                          </label>
                          </div>
                          {/* <small>File format *.pdf (max 5mb)</small> */}
                        </Form>
                      </Col>
                    )
                  }
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Description</label>
                      <Input
                        type="textarea"
                        onKeyPress={this.keyPressed}
                        name="document_description"
                        placeholder="Document Description"
                        onChange={this.handleChangeStringSupplier}
                        required
                        className={
                          this.state.paramErrorSupplier.document_description
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplier.document_description}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" onChange={this.handleSendEmail} /> Send Email
                        </Label>
                      </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button
                  color="success"
                  type="button"
                  onClick={() => this.handleSubmitSupplier()}
                >
                   {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("supplierModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.supplierModalUpdate}
              toggle={() => this.toggleModal("supplierModalUpdate")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Update Document (PO Bundle)
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("supplierModalUpdate")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">No</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="document_no"
                        placeholder="Document no"
                        onChange={this.handleChangeStringSupplierUpdate}
                        defaultValue={this.state.dataSupplierUpdate.document_no}
                        required
                        className={
                          this.state.paramErrorSupplierUpdate.document_no ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplierUpdate.document_no}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Name</label>
                      <Input
                        type="text"
                        onKeyPress={this.keyPressed}
                        name="document_name"
                        placeholder="Document name"
                        onChange={this.handleChangeStringSupplierUpdate}
                        defaultValue={this.state.dataSupplierUpdate.document_name}
                        required
                        className={
                          this.state.paramErrorSupplierUpdate.document_name
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplierUpdate.document_name}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" checked={this.state.sizeFileUpdate} onChange={this.handleSizeFileUpdate} /> Link?
                      </Label>
                    </FormGroup>
                  </Col>
                  {this.state.sizeFileUpdate ?
                    (
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Link File</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="document_file_link"
                            placeholder="Link File"
                            onChange={this.handleChangeStringSupplierUpdate}
                            defaultValue={this.state.dataSupplierUpdate.document_file_link}
                            required
                            className={
                              this.state.paramErrorSupplierUpdate.document_file_link
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramErrorSupplierUpdate.document_file_link}
                          </div>
                        </FormGroup>
                      </Col>
                    ) :
                    (
                      <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                        <label className="form-control-label">File</label>
                        <Form>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="customFileLang"
                              lang="en"
                              type="file"
                              name="document_file"
                              onChange={this.onFileChangeSupplierUpdate}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileLang"
                            >
                              Select file
                          </label>
                          </div>
                          {/* <small>File format *.pdf (max 5mb)</small> */}
                        </Form>
                      </Col>
                    )
                  }
                  <Col className="col-lg-12 col-md-12 col-sm-12" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Description</label>
                      <Input
                        type="textarea"
                        onKeyPress={this.keyPressed}
                        name="document_description"
                        placeholder="Document Description"
                        onChange={this.handleChangeStringSupplierUpdate}
                        defaultValue={this.state.dataSupplierUpdate.document_description}
                        required
                        className={
                          this.state.paramErrorSupplierUpdate.document_description
                            ? "is-invalid"
                            : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorSupplierUpdate.document_description}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button
                  color="success"
                  type="button"
                  onClick={() => this.handleSubmitSupplierUpdate()}
                >
                   {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Save
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Save</span>
                    )}
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("supplierModalUpdate")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </Col>
        </Row>
        {this.state.alert}
      </>
    );
  }
}

DocumentsVendor.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
};

export default DocumentsVendor;
