import React, { Component } from "react";
import {
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";
//services
import config from "services/config";

//packages
import axios from "axios";
// import Pagination from "react-bootstrap-pagination-logic";

//redux
// import { connect } from "react-redux";
// import { getDataPoVendor } from "./Redux/poVendorActions";
// import moment from "moment";
// react component used to create sweet alerts
// import SweetAlert from "react-bootstrap-sweetalert";
import Spacer from "components/Headers/Spacer";
import StandardHeader from "components/Headers/Breadcrumb";
import ListUsers from "./TabGoodReceive";
// class ListGoodReceive extends PureComponent {
  class ListGoodReceive extends Component {
    
    state = {
      listUsers: [],
    };
    
    componentDidUnmount() {
    console.log("cek");
    this.mounted = true;
    this.getListUsers();
  }

  getListUsers = () => {
    axios
      .post(
        config.API_URL + "/companies/view_vendor",
        { company_id: this.props.match.params.companyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listUsers: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  
  render() {
    return (
      <div>
        <Spacer/>
        <Container  fluid>
              <div className="card-wrapper">
                <ListUsers
                  list={this.state.listUsers}
                  info={ <Row>
                     <Col className=" col-md-6  col-sm-6  col-12"  >
                  <StandardHeader parentName="Good Receive" link="good-receive" />
                    </Col>
                    <Col className="col-md-3  col-sm-12  col-12 ml-auto">
                      {/* <Col md={9} className="text-left"> */}
                        <InputGroup size="sm">
                          <Input
                            bsSize="sm"
                            type="search"
                            // autofocus="autofocus"
                            className="search"
                            id="search"
                            placeholder="Search"
                            result
                          />
                          <InputGroupText className="pt-0 pb-0 pl-2 pr-2">
                            <a
                              href=" "
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                                color: "grey",
                              }}
                            >
                              <i
                                class="fa fa-search"
                                aria-hidden="true"
                                for="search"
                              ></i>
                            </a>
                          </InputGroupText>
                        </InputGroup>
                      {/* </Col> */}
                    </Col>
                  </Row>}
                  update={this.handleUpdateUser}
                  delete={this.confirmAlertUser}
                />
              </div>
        </Container>
      </div>
    );
  }
}

export default (ListGoodReceive);
