import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
class PoHeader extends React.Component {
  goBack = (e) => {
    e.preventDefault();
    window.history.back();
  };
  render() {
    return (
      <>
        <div className="header pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 d-inline-block mb-0 ">{this.props.name}</h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <Link to={"/admin/" + this.props.link}>
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={"/admin/" + this.props.link}>
                        {this.props.parentName}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/admin/" onClick={this.goBack}>
                        {this.props.childName}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

PoHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  childName: PropTypes.string,
  link: PropTypes.string,
};

export default PoHeader;
