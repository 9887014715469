import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Alert,
  CardBody,
  FormText,
} from "reactstrap";
//services
import config from "services/config";

//packages
import axios from "axios";
import Pagination from "react-bootstrap-pagination-logic";

//redux
import { connect } from "react-redux";
import { getDataPoVendor } from "./Redux/poVendorActions";
import moment from "moment";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/StandardHeader";
class ListPoVendor extends PureComponent {
  state = {
    actionListPo: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_id: config.COMPANY_ID,
      po_no: "",
      po_description: "",
    },

    alert: null,
    listPo: [],
    total_pages: 0,
    total_items: 0,
    isFilter: false,
    isFilterLoading: false,
    isResetLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDataListPo();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeString = (event) => {
    this.setState({
      actionListPo: {
        ...this.state.actionListPo,
        [event.target.name]: event.target.value,
      },
    });
  };

  getDataListPo = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(config.API_URL + "/po/list_po_vendor", this.state.actionListPo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listPo: res.data.data,
                total_pages: res.data.total_pages,
                total_items: res.data.total_items,
              },
              () => {
                this.props.dispatch(getDataPoVendor(this.state.listPo));
                if (this.state.isFilterLoading) {
                  this.setState({
                    isFilterLoading: false,
                    isFilter: true,
                  });
                }
                if (this.state.isResetLoading) {
                  this.setState({
                    isResetLoading: false,
                    isFilter: true,
                  });
                }
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  handleDetails = (data) => {
    this.props.history.push("/admin/po-vendor-details/" + data.po_id);
  };

  handlePagination = (numPage) => {
    this.setState(
      {
        actionListPo: {
          ...this.state.actionListPo,
          page: numPage,
        },
      },
      () => {
        this.getDataListPo();
      }
    );
  };

  handleItemPerpage = (e) => {
    this.setState(
      {
        actionListPo: {
          ...this.state.actionListPo,
          page: 1,
          items_per_page: parseInt(e.target.value),
        },
      },
      () => {
        this.getDataListPo();
      }
    );
  };

  handletoAdd = () => {
    this.props.history.push("/admin/create-job-scm");
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleFilter = () => {
    this.setState(
      {
        actionListPo: {
          ...this.state.actionListPo,
          page: 1,
        },
      },
      () => {
        this.getDataListPo();
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        actionListPo: {
          page: 1,
          items_per_page: 10,
          order_by_field: "created_at",
          order_by_direction: "DESC",
          company_id: config.COMPANY_ID,
          po_no: "",
          po_description: "",
        },
      },
      () => {
        if (this.state.isResetLoading === false) {
          this.setState(
            {
              isResetLoading: true,
            },
            () => {
              this.getDataListPo();
            }
          );
        }
      }
    );
  };


  render() {
    let fromItem = null;
    let toItem = null;

    if (this.state.total_pages === this.state.actionListPo.page) {
      toItem = this.state.total_items;
    } else {
      toItem = this.props.dataPoVendor.length * this.state.actionListPo.page;
    }
    if (this.state.actionListPo.page === 1) {
      fromItem = 1;
    } else if (this.state.total_pages === this.state.actionListPo.page) {
      fromItem = this.state.total_items - this.props.dataPoVendor.length + 1;
    } else {
      fromItem =
        this.props.dataPoVendor.length * (this.state.actionListPo.page - 1) + 1;
    }

    const availDataPo = this.props.dataPoVendor.map((po) => {
      var a = moment(new Date());
      var b = moment(po.after_close_po);
      var c = b.diff(a, 'days');
      return (
        <tr
          key={po.po_id}
          style={{ cursor: "pointer" }}
          onDoubleClick={() => this.handleDetails(po)}
        >
          <td>{po.po_no}</td>
          <td>{po.po_description} </td>
          <td>{moment(po.po_date).format("ll")}</td>
          <td>{moment(po.po_delivery_date).format("ll")}</td>
          <td>{moment(po.created_at).format("lll")}</td>
          <td>{moment(po.updated_at).format("lll")}</td>
          { c > 0 ?
            (
              <>
                {po.po_file === null || po.po_file === '' ?
                  <td>Empty</td> :
                  <td>
                    <Button
                      className="btn-neutral text-uppercase"
                      size="sm"
                      type="button"
                      onClick={() =>
                        window.open(
                          config.BUCKET_URL + po.po_file,
                          "_blank"
                        )
                      }
                    >
                      <span className="btn-inner--text ml-2">Download PDF</span>
                    </Button>
                  </td>
                }

                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem onClick={() => this.handleDetails(po)}>
                        Details
                </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </>
            ) : (
              <>
                <td>Closed</td>
                <td>Closed</td>
              </>
            )

          }

        </tr>
      );
    });

    return (
      <>
        <StandardHeader name="Po" parentName="Po" link="po-vendor" />
        <Container className="mt--6" fluid>
          {/* <Row className="mb-4">
            <Col>
              <Button
                color="success"
                type="button"
                className="btn-icon"
                onClick={() => this.handletoAdd()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                Create Po
              </Button>
            </Col>
          </Row> */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">Filter Po</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">No</label>
                        <Input
                          id="example-date-input"
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="po_no"
                          placeholder="No"
                          value={this.state.actionListPo.po_no}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          id="example-date-input"
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="po_description"
                          placeholder="Description"
                          value={this.state.actionListPo.po_description}
                          onChange={this.handleChangeString}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <div className="text-center">
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isFilterLoading ? true : false}
                          onClick={() => this.handleFilter()}
                        >
                          {this.state.isFilterLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Filter
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Filter</span>
                          )}
                        </Button>
                      </div>
                    </Col>
                    <Col
                      style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        block
                        color="danger"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isResetLoading ? true : false}
                        onClick={() => this.handleReset()}
                      >
                        {this.state.isResetLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Reset
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Reset</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">List Po</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <div>
                    {this.props.dataPoVendor.length < 5 ? (
                      <div style={{ height: 323 }}>
                        {this.props.dataPoVendor.length === 0 ? (
                          <Alert color="secondary">
                            <strong>Data not found!</strong> Please check again!
                          </Alert>
                        ) : (
                          <>
                            <Table
                              className="align-items-center table-flush"
                              hover
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">No</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Delivery Date</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Updated At</th>
                                  <th scope="col">File</th>
                                  <th>ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>{availDataPo}</tbody>
                            </Table>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          hover
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">Description</th>
                              <th scope="col">Date</th>
                              <th scope="col">Delivery Date</th>
                              <th scope="col">Created At</th>
                              <th scope="col">Updated At</th>
                              <th scope="col">File</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>{availDataPo}</tbody>
                        </Table>
                      </>
                    )}
                  </div>

                  <CardFooter className="py-4">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Col md="3" sm="6">
                          <Form inline>
                            <FormGroup>
                              <FormText className="mr-sm-2">Show</FormText>

                              <Input
                                className="form-control-sm mr-sm-2"
                                type="select"
                                onChange={this.handleItemPerpage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </Input>
                              <FormText>
                                entries. Showing rows {fromItem} to {toItem} of{" "}
                                {this.state.total_items}
                              </FormText>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Col>
                      <Col className="text-right" xs="4">
                        <nav aria-label="...">
                          <Pagination
                            current_page={this.state.actionListPo.page}
                            last_page={this.state.total_pages}
                            position="right"
                            handlePageChange={this.handlePagination}
                          />
                        </nav>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Row>
        </Container>

        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataPoVendor: state.po_vendor.dataPoVendor,
  };
};

export default connect(mapStateToProps)(ListPoVendor);
