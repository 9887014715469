import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import {
  Card,
  CardHeader,
  Alert,
  UncontrolledTooltip,
  Col,
  Row,
  Badge,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const TaskFeedNonAssign = (props) => {
  const info = props.info;
  const toggle = props.toggleModal;
  const actionsDate = (cell, row) => {
    return moment(cell).format("LL");
  };
  const actionDateTime = (cell, row) => {
    return moment(cell).format("lll");
  };
  const actionsBoolean = (cell, row) => {
    if (cell === 0) {
      return (
        <Badge className="badge-dot mr-4" color="">
          <i className="bg-warning" />
          <span className="status">No</span>
        </Badge>
      );
    }
    if (cell === 1) {
      return (
        <Badge className="badge-dot mr-4" color="">
          <i className="bg-success" />
          <span className="status">Yes</span>
        </Badge>
      );
    }
  };
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <a
          className="table-action"
          id={"tooltip12312"}
          style={{ cursor: "pointer" }}
          onClick={() => props.update(row)}
        >
          <i className="fas fa-user-edit" />
        </a>
        <UncontrolledTooltip delay={0} target={"tooltip12312"}>
          Update
        </UncontrolledTooltip>
      </>
    );
  };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="task_feed_id"
          columns={[
            {
              dataField: "task_feed_description",
              text: "Description",
              sort: true,
            },
            {
              dataField: "start_date",
              text: "Start Date",
              sort: true,
              formatter: actionsDate,
            },
            {
              dataField: "due_date",
              text: "Due Date",
              sort: true,
              formatter: actionsDate,
            },
            {
              dataField: "task_completed",
              text: "Complete",
              sort: true,
              formatter: actionsBoolean,
            },
            {
              dataField: "note",
              text: "Note",
            },
            {
              text: "Actions",
              formatter: actionsFormatter,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">{info}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default TaskFeedNonAssign;
