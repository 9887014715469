
import React, { PureComponent } from "react";

import {
    Row,
    Col,
    Container
  } from "reactstrap";

class Title extends PureComponent {
  render() {
    return (
      <>
        <Container className="mt--2 ml--2">
        <Row className="align-items-center py-4">
            <Col lg="6" xs="7">
              <h6 className="h2 text-black d-inline-block mb-0">
                {this.props.name}
              </h6>{" "}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Title;
