import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Modal,
} from "reactstrap";
import Spacer from "components/Headers/Spacer";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
// import DocumentsVendor from "../Admin/PoVendor/DocumentsVendor/DocumentsVendor";
// import KickoffVendor from "../Admin/PoVendor/KickoffVendor/KickoffVendor";
// import MaterialsVendor from "../Admin/PoVendor/MaterialsVendor/MaterialsVendor";
// import ProgReportVendor from "../Admin/PoVendor/ProgReportVendor/ProgReportVendor";
// import PccVendor from "../Admin/PoVendor/PccVendor/PccVendor";
// import QualityVendor from "../Admin/PoVendor/QualityVendor/QualityVendor";
// import CompleteVendor from "../Admin/PoVendor/CompleteVendor/CompleteVendor";
// import TaskFeedVendor from "../Admin/PoVendor/TaskFeedVendor/TaskFeedVendor";
// import TaskFeedAssign from "../Admin/PoVendor/TaskFeedVendor/TaskFeedAssign";
// import { validateTaskFeed } from "services/validate";
class PoVendorDetails extends PureComponent {
  state = {
    data: {},
   
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_vendor",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeed();
      this.getTaskFeedAssign();
      this.setState({
        data: response.data.data[0],
        dataSubmitCompleteTask: {
          task_completed: response.data.data[0].task_completed,
          task_feed_id: response.data.data[0].task_feed_id,
          note: response.data.data[0].note,
          task_feed_description: response.data.data[0].task_feed_description
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleRefresh = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_vendor",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeed();
      this.getTaskFeedAssign();
      this.setState({
        data: response.data.data[0],
        dataSubmitCompleteTask: {
          task_completed: response.data.data[0].task_completed,
          task_feed_id: response.data.data[0].task_feed_id,
          note: response.data.data[0].note,
          task_feed_description: response.data.data[0].task_feed_description
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    return (
      <>
        <Spacer name="Setting" parentName="Setting" link="setting" />

        {this.state.alert}
      </>
    );
  }
}

export default PoVendorDetails;
