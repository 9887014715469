import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Modal
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import PoHeader from "components/Headers/PoHeader";
import config from "services/config";
import moment from "moment";
import DocumentsSCM from "./DocumentsSCM/DocumentsSCM";
import KickoffSCM from "./KickoffSCM/KickoffSCM";
import MaterialsSCM from "./MaterialsSCM/MaterialsSCM";
import ProgReportSCM from "./ProgReportSCM/ProgReportSCM";
import PccSCM from "./PccSCM/PccSCM";
import QualitySCM from "./QualitySCM/QualitySCM";
import ActivitesSCM from "./ActivitiesSCM/ActivitiesSCM";
import CompleteSCM from "./CompleteSCM/CompleteSCM";
import TaskFeedSCM from "./TaskFeedSCM/TaskFeedSCM";
import { validateTaskFeed } from "services/validate";
import TaskFeedNonAssign from "./TaskFeedSCM/TaskFeedNonAssign";
class PoDetails extends PureComponent {
  state = {
    data: {},
    dataActivities: [],
    dataTaskFeed: [],
    filledDataTaskFeed: {
      email: config.EMAIL,
      task_feed_owner: 'purchaser',
      start_date: '',
      due_date: '',
      task_feed_description: '',
    },
    filledDataTaskFeedUpdate: {},
    activeTab: "1",
    isLoading: false,
    alert: null,
    paramError: {},
    taskFeedModal: false,
    taskFeedModalUpdate: false,
    paramErrorTaskFeed: {},
    paramErrorTaskFeedUpdate: {},
    dataSubmitCompleteTask: {
      task_completed: "",
      task_feed_id: "",
      note: "",
      task_feed_description: ""
    },
    taskFeedModalUpdateComplete: false,
    dataTaskFeedNonAssign: [],
    filledTaskFeed: {
      task_feed_description: "",
      start_date: "",
      due_date: "",
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_scm",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const response_activities = await axios.post(
        config.API_URL + "/activities/list_activities_scm",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_scm",
        { po_id: this.props.match.params.poId, task_feed_owner: 'purchaser' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeedNonAssign();
      this.setState({
        dataActivities: response_activities.data.data,
        data: response.data.data[0],
        dataTaskFeed: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getTaskFeed = async () => {
    try {
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_scm",
        { po_id: this.props.match.params.poId, task_feed_owner: 'purchaser' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        dataTaskFeed: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  }

  getTaskFeedNonAssign = async () => {
    try {
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_scm",
        { po_id: this.props.match.params.poId, task_feed_owner: 'supplier' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        dataTaskFeedNonAssign: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitTaskFeed = () => {
    this.setState({
      filledDataTaskFeed: {
        po_id: this.state.data.po_id,
        job_id: this.state.data.job_id,
        company_id: this.state.data.company_id,
        ...this.state.filledDataTaskFeed
      },
      isLoading: true
    }, () => {
      const paramErrorTaskFeed = validateTaskFeed(this.state.filledDataTaskFeed);
      this.setState({ paramErrorTaskFeed });
      if (Object.keys(paramErrorTaskFeed).length === 0) {
        axios
          .post(
            config.API_URL + "/task_feed/create_task_feed_scm",
            this.state.filledDataTaskFeed,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: config.TOKEN,
              },
            }
          )
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data.message);
                this.setState({
                  isLoading: false,
                });

                this.getTaskFeed();
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(this.state.filledDataTaskFeed.task_feed_description);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
      } else {
        this.failedAlert("Validation Errors");
        this.setState({
          isLoading: false,
        });
      }
    })
  }

  handleSubmitTaskFeedUpdate = () => {
    const paramErrorTaskFeedUpdate = validateTaskFeed(this.state.filledDataTaskFeedUpdate);
    this.setState({ paramErrorTaskFeedUpdate, isLoading: true });
    if (Object.keys(paramErrorTaskFeedUpdate).length === 0) {
      axios
        .post(
          config.API_URL + "/task_feed/update_task_feed_scm",
          this.state.filledDataTaskFeedUpdate,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({
                isLoading: false,
              });

              this.getTaskFeed();
            }
          },
          (err) => {
            if (this.mounted) {
              if (err.response !== undefined) {
                this.failedAlert(this.state.filledDataTaskFeedUpdate.task_feed_description);
                this.setState({ isLoading: false });
              }
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  }

  handleSubmitTaskFeedComplete = () => {
    axios
      .post(
        config.API_URL + "/task_feed/update_task_completed_scm",
        {...this.state.dataSubmitCompleteTask, po_id: this.state.data.po_id, company_id: this.state.data.company_id},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.message);
            this.getTaskFeedNonAssign();
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert("Task");
              this.setState({ isLoading: false });
            }
          }
        }
      );
  };

  handleChangeStringTaskFeed = (e) => {
    this.setState({
      filledDataTaskFeed: {
        ...this.state.filledDataTaskFeed,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeStringTaskFeedComplete = (event) => {
    this.setState({
      dataSubmitCompleteTask: {
        ...this.state.dataSubmitCompleteTask,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeStringTaskFeedCompleteNote = (event) => {
    this.setState({
      dataSubmitCompleteTask: {
        ...this.state.dataSubmitCompleteTask,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleChangeStringTaskFeedUpdate = (e) => {
    this.setState({
      filledDataTaskFeedUpdate: {
        ...this.state.filledDataTaskFeedUpdate,
        [e.target.name]: e.target.value,
      },
    });
  }

  removeTaskFeed = (data) => {
    axios
      .post(
        config.API_URL + "/task_feed/delete_task_feed_scm",
        { task_feed_id: data.task_feed_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertTaskFeed();
          this.getTaskFeed();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  confirmAlertTaskFeed = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeTaskFeed(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.task_feed_description}`} task, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlertTaskFeed = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Task has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleTaskFeedUpdate = (e) => {
    this.setState({
      taskFeedModalUpdate: true,
      filledDataTaskFeedUpdate: {
        task_feed_id: e.task_feed_id,
        po_id: e.po_id,
        job_id: e.job_id,
        company_id: e.company_id,
        start_date: e.start_date,
        due_date: e.due_date,
        task_feed_description: e.task_feed_description,
      }
    })
  }

  handleUpdateStatus = (e) => {
    this.setState({
      taskFeedModalUpdateComplete: true,
      filledTaskFeed: e,
      dataSubmitCompleteTask: {
        task_feed_id: e.task_feed_id,
        task_completed: e.task_completed,
        note: e.note,
        task_feed_description: e.task_feed_description
      },
    });
  };

  handleClosePo = () => {
    axios
      .post(
        config.API_URL + "/po/update_close_po",
        {
          po_id: this.state.data.po_id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.message);
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert('Unsuccessfull to close po');
            }
          }
        }
      );
  }

  handleRefresh = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_scm",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const response_activities = await axios.post(
        config.API_URL + "/activities/list_activities_scm",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_scm",
        { po_id: this.props.match.params.poId, task_feed_owner: 'purchaser' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeedNonAssign();
      this.setState({
        dataActivities: response_activities.data.data,
        data: response.data.data[0],
        dataTaskFeed: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <>
        <PoHeader
          name="PO Details"
          parentName="Jobs"
          childName="Job Details"
          link="jobs-scm"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          PO No : {this.state.data.po_no}
                        </h3>
                      </Col>
                      <Col className="text-right">
                        <Button
                          className="btn-neutral text-uppercase"
                          size="sm"
                          type="button"
                          onClick={() =>
                            window.open(
                              config.BUCKET_URL +
                              this.state.data.po_file,
                              "_blank"
                            )
                          }
                        >
                          <span className="btn-inner--text ml-2">
                            Download PDF
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-4">
                      <Col md="6" xs="6">
                        <Row>
                          <Col>
                            <h5 className="text-uppercase text-muted mb-0">
                              Status
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.data.close_po === 1 ? 'Closed' : 'Open'}
                            </span>
                          </Col>
                          {this.state.data.close_po === 1 ?
                            <Col>
                              <h5 className="text-uppercase text-muted mb-0">
                                Totally Closed At
                            </h5>
                              <span className="h2 font-weight-bold mb-0">
                                {moment(this.state.data.after_close_po).format('ll')}
                              </span>
                            </Col> : null
                          }
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Date</label>
                          <Input
                            type="text"
                            value={moment(this.state.data.po_date).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Delivery Date
                          </label>
                          <Input
                            type="text"
                            value={moment(
                              this.state.data.po_delivery_date
                            ).format("lll")}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Company</label>
                          <Input
                            type="text"
                            value={this.state.data.company_name}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job No</label>
                          <Input type="text" value={this.state.data.job_no} />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job Name</label>
                          <Input type="text" value={this.state.data.job_name} />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Created At
                          </label>
                          <Input
                            type="text"
                            value={moment(this.state.data.created_at).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Updated At
                          </label>
                          <Input
                            type="text"
                            value={moment(this.state.data.updated_at).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Description
                          </label>
                          <Input
                            type="textarea"
                            value={this.state.data.po_description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          {/* <Row>
          <Col className="text-right">
              <Button
                className="btn-neutral text-uppercase"
                size="sm"
                type="button"
                onClick={() => this.handleRefresh()
                }
              >
                <span className="btn-inner--text ml-2">
                  Refresh
                </span>
              </Button>
            </Col>
          </Row> */}
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "1"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  PO Bundle
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "2"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Minutes of Meeting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "3"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Logistic Matter
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "4"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Job Progress Report
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "5"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  Design Changes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "6"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggle("6");
                  }}
                >
                  RN/NC
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "7"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "7",
                  })}
                  onClick={() => {
                    this.toggle("7");
                  }}
                >
                  Finish Job
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="mt-4" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <DocumentsSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="2">
                <KickoffSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="3">
                <MaterialsSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="4">
                <ProgReportSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="5">
                <PccSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="6">
                <QualitySCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="7">
                {this.state.data.close_po === 0 ?
                <Row className="mb-4">
                  <Col>
                    <Button
                      type="button"
                      className="btn-icon"
                      onClick={() => this.handleClosePo()}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-clock-o" />
                      </span>
                      <span className="btn-inner--text">Close PO</span>
                    </Button>
                  </Col>
                </Row>:null

                }
                <CompleteSCM
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
            </TabContent>
          </div>
          <Row>
            <Col sm="6">
              <TaskFeedSCM
                list={this.state.dataTaskFeed}
                info={"Task Feed (Purchaser)"}
                update={this.handleTaskFeedUpdate}
                delete={this.confirmAlertTaskFeed}
                toggleModal={this.toggleModal} />
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.taskFeedModal}
                toggle={() => this.toggleModal("taskFeedModal")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-default">
                    Add Task
                </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("taskFeedModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Description</label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          onChange={this.handleChangeStringTaskFeed}
                          required
                          className={
                            this.state.paramErrorTaskFeed.task_feed_description ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeed.task_feed_description}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          onChange={this.handleChangeStringTaskFeed}
                          required
                          className={
                            this.state.paramErrorTaskFeed.start_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeed.start_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          onChange={this.handleChangeStringTaskFeed}
                          required
                          className={
                            this.state.paramErrorTaskFeed.due_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeed.due_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="success"
                    type="button"
                    onClick={() => this.handleSubmitTaskFeed()}
                  >
                     {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("taskFeedModal")}
                  >
                    Close
                </Button>
                </div>
              </Modal>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.taskFeedModalUpdate}
                toggle={() => this.toggleModal("taskFeedModalUpdate")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-default">
                    Update Task
                </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("taskFeedModalUpdate")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Description</label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          onChange={this.handleChangeStringTaskFeedUpdate}
                          value={this.state.filledDataTaskFeedUpdate.task_feed_description}
                          required
                          className={
                            this.state.paramErrorTaskFeedUpdate.task_feed_description ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeedUpdate.task_feed_description}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          onChange={this.handleChangeStringTaskFeedUpdate}
                          value={moment(this.state.filledDataTaskFeedUpdate.start_date).format("YYYY-MM-DD")}
                          required
                          className={
                            this.state.paramErrorTaskFeedUpdate.start_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeedUpdate.start_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          onChange={this.handleChangeStringTaskFeedUpdate}
                          required
                          value={moment(this.state.filledDataTaskFeedUpdate.due_date).format("YYYY-MM-DD")}
                          className={
                            this.state.paramErrorTaskFeedUpdate.due_date ? "is-invalid" : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorTaskFeedUpdate.due_date}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="success"
                    type="button"
                    onClick={() => this.handleSubmitTaskFeedUpdate()}
                  >
                     {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Save
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Save</span>
                    )}
                </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("taskFeedModalUpdate")}
                  >
                    Close
                </Button>
                </div>
              </Modal>
            </Col>
            <Col sm="6">
              <TaskFeedNonAssign
                list={this.state.dataTaskFeedNonAssign}
                info={"Task Feed (Supplier)"}
                update={this.handleUpdateStatus}
                toggleModal={this.toggleModal}
              />
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.taskFeedModalUpdateComplete}
                toggle={() => this.toggleModal("taskFeedModalUpdateComplete")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-default">
                    Task
                  </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("taskFeedModalUpdateComplete")
                    }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          disabled
                          value={
                            this.state.filledTaskFeed.task_feed_description
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          value={moment(
                            this.state.filledTaskFeed.start_date
                          ).format("YYYY-MM-DD")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          disabled
                          value={moment(
                            this.state.filledTaskFeed.due_date
                          ).format("YYYY-MM-DD")}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Complete</label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="task_completed"
                          placeholder="Complete"
                          onChange={this.handleChangeStringTaskFeedComplete}
                          required
                          value={
                            this.state.dataSubmitCompleteTask.task_completed
                          }
                        >
                          <option value={""}>Select</option>
                          <option value={0}>No</option>
                          <option value={1}>Yes</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Notes</label>
                        <Input
                          type="textarea"
                          name="note"
                          placeholder="Notes"
                          onChange={this.handleChangeStringTaskFeedCompleteNote}
                          required
                          value={
                            this.state.dataSubmitCompleteTask.note
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="success"
                    type="button"
                    onClick={() => this.handleSubmitTaskFeedComplete()}
                  >
                     {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                  </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("taskFeedModalUpdateComplete")
                    }
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActivitesSCM
                list={this.state.dataActivities}
                info={"Activities"}
              />
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default PoDetails;
