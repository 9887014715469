/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Container,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Input,
} from "reactstrap";
//services
import config from "services/config";

//packages
import axios from "axios";
// import Pagination from "react-bootstrap-pagination-logic";

//redux
// import { connect } from "react-redux";
// import { getDataPoVendor } from "./Redux/poVendorActions";
// import moment from "moment";
// react component used to create sweet alerts
// import SweetAlert from "react-bootstrap-sweetalert";
import StandardHeader from "components/Headers/Breadcrumb";
import List from "./TabOrderAcknowledge";
// class ListPo extends PureComponent {
class ListOrderAcknowledge extends Component {
  state = {
    list: [],
  };

  componentDidUnmount() {
    console.log("cek");
    this.mounted = true;
    // this.getListUsers();
  }

  // getListUsers = () => {
  //   axios
  //     .post(
  //       config.API_URL + "/companies/view_vendor",
  //       { company_id: this.props.match.params.companyId },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: config.TOKEN,
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         if (this.mounted) {
  //           this.setState({
  //             listUsers: res.data.data,
  //           });
  //         }
  //       },
  //       (err) => {
  //         console.log("error: ", err);
  //       }
  //     );
  // };
  isEdit = () => {
    console.log("cek");
  };
  handleAdd = () => {
    this.props.history.push("/vendor/order-acknowledge-form");
  };

  render() {
    return (
      <div>
        <StandardHeader
          // name="Order Acknowledge"
          parentName="Order Acknowledge"
          link="order-acknowledge"
        />
        <Container className="" fluid>
          <div className="card-wrapper">
            <List
              list={this.state.list}
              info={
                <Row>
                  <Col className="text-left" md={3}>
                    {/* <Col md={9} className="text-left"> */}
                      <InputGroup size="sm">
                        <Input
                          bsSize="sm"
                          type="search"
                          // autofocus="autofocus"
                          className="search"
                          id="search"
                          placeholder="Search"
                          result
                        />
                        <InputGroupText className="pt-0 pb-0 pl-2 pr-2">
                          <a
                            href=" "
                            style={{
                              cursor: "pointer",
                              objectFit: "cover",
                              color: "grey",
                            }}
                          >
                            <i
                              class="fa fa-search"
                              aria-hidden="true"
                              for="search"
                            ></i>
                          </a>
                        </InputGroupText>
                      </InputGroup>
                    {/* </Col> */}
                  </Col>
                  <Col className="text-right mr-4">
                    {/* <Col md={3} className="text-right text-center"> */}
                      <a
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                          color: "grey",
                        }}
                        onClick={() => this.handleAdd()}
                      >
                        <i className="ni ni-fat-add" aria-hidden="true" />
                        &nbsp; Add
                      </a>
                      {/* &emsp; */}
                      {/* <a
                          
                          className="btn-icon"
                          // onClick={() => this.handletoAdd()}
                          >
                     <i class="fa fa-pencil-square-o" aria-hidden="true"/>&nbsp;
    
                            Edit
                          </a> */}
                    {/* </Col> */}
                  </Col>
                </Row>
              }
              handleEdit={this.isEdit}
              delete={this.confirmAlertUser}
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default ListOrderAcknowledge;
