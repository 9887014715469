import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import StandardHeader from "components/Headers/StandardHeader.js";
import { validateJobs, validatePo } from "services/validate";
import config from "services/config";
import axios from "axios";
import { styleInputSelect } from "services/styles";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDataJobsSCM } from "./Redux/jobsSCMActions";
import ListPo from "./PoSCM/ListPoSCM";
import moment from 'moment'

class JobsSCMDetails extends PureComponent {
  state = {
    data: {
      job_id: "",
      jod_no: "",
      job_name: "",
      job_description: "",
    },
    viewData: {
      created_at: "",
      updated_at: "",
    },
    actionListJobs: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      job_name: "",
      job_no: "",
    },
    dataPo: {
      job_id: 0,
      company_id: "",
      po_no: "",
      po_date: "",
      po_delivery_date: "",
      po_description: "",
      po_file: "",
    },
    dataUpdatePo: {
      job_id: 0,
      company_id: "",
      po_no: "",
      po_date: "",
      po_delivery_date: "",
      po_description: "",
      po_file: "",
    },
    actionListCompanies: {
      page: 1,
      items_per_page: 10,
      order_by_field: "created_at",
      order_by_direction: "DESC",
      company_name: "",
    },
    isUploadExcel: false,
    dataExcel: {},
    selectedOptionCompanies: [],
    updatePo: false,
    listJobs: [],
    listPo: [],
    paramError: {},
    paramErrorPo: {},
    isLoading: false,
    alert: null,
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/jobs/view_job_scm",
        { job_id: this.props.match.params.jobId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        data: {
          job_id: response.data.data[0].job_id,
          job_no: response.data.data[0].job_no,
          job_name: response.data.data[0].job_name,
          job_description: response.data.data[0].job_description,
        },
        viewData: {
          created_at: response.data.data[0].created_at,
          updated_at: response.data.data[0].updated_at,
        },
      });
    } catch (err) {
      console.log(err);
    }
    this.getListPo();
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getListPo = () => {
    axios
      .post(
        config.API_URL + "/po/list_po_scm",
        { job_id: this.props.match.params.jobId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState({
              listPo: res.data.data,
            });
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  fetchDataCompanies = (inputValue) => {
    let searchTerm = inputValue;

    if (!inputValue || inputValue === " ") {
      searchTerm = "";
    }

    this.setState({
      actionListCompanies: {
        ...this.state.actionListCompanies,
        company_name: searchTerm,
      },
    });

    const newRequest = axios.post(
      config.API_URL + "/companies/companies_public",
      this.state.actionListCompanies,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      }
    );

    if (newRequest) {
      return newRequest.then((response) => {
        const compare = response.data.data.filter((i) =>
          i.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return compare.map((data) => ({
          label: data.company_name,
          value: data.company_id,
        }));
      });
    }
  };
  onSearchChangePo = (selectedOptionCompanies) => {
    this.setState({
      selectedOptionCompanies: selectedOptionCompanies,
      dataPo: {
        ...this.state.dataPo,
        company_id: selectedOptionCompanies.value,
      },
    });
  };
  onSearchChangeUpdatePo = (selectedOptionCompanies) => {
    this.setState({
      selectedOptionCompanies: selectedOptionCompanies,
      dataUpdatePo: {
        ...this.state.dataUpdatePo,
        company_id: selectedOptionCompanies.value
      }
    })
  }
  getDataListJobs = () => {
    axios
      .post(config.API_URL + "/jobs/list_jobs_scm", this.state.actionListJobs, {
        headers: {
          "Content-Type": "application/json",
          Authorization: config.TOKEN,
        },
      })
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                listJobs: res.data.data,
              },
              () => {
                this.props.dispatch(getDataJobsSCM(this.state.listJobs));
              }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  remove = (id) => {
    axios
      .post(
        config.API_URL + "/jobs/delete_job_scm",
        { job_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlert();
          this.getDataListJobs();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };

  putDataToAPI = () => {
    this.setState({ isLoading: true });
    const paramError = validateJobs(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      axios
        .post(config.API_URL + "/jobs/update_job_scm", this.state.data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        })
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(this.state.data.job_name);
              this.setState({ isLoading: false });
              this.getListPo();
            }
          },
          (err) => {
            if (this.mounted) {
              this.failedAlert(this.state.data.job_name);
              this.setState({ isLoading: false });
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = () => {
    this.putDataToAPI();
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  confirmAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.remove(data.job_id)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.job_name}`} job, You won't be able to revert this!
        </SweetAlert>
      ),
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.handletoMainPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Job has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleUpdatePo =  async (data) => {
    let response = await fetch(config.BUCKET_URL + data.po_file);
    let data_ = await response.blob();
    let metadata = {
      type: "file/pdf",
    };
    let file = await new File([data_], + data.po_no + ".pdf", metadata);

    await this.setState({
      dataUpdatePo: {
        po_id: data.po_id,
        job_id: this.props.match.params.jobId,
        company_id: data.company_id,
        po_no: data.po_no,
        po_date: moment(data.po_date).format('YYYY-MM-DD'),
        po_delivery_date: moment(data.po_delivery_date).format('YYYY-MM-DD'),
        po_description: data.po_description,
        po_file: file,
      },
      selectedOptionCompanies: {
        value: data.company_id,
        label: data.company_name
      },
      updatePo: true,
    });
  };

  handletoMainPage() {
    this.props.history.push("/admin/jobs");
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  handleSubmitPo = () => {
    this.setState(
      {
        isLoading: true,
        dataPo: {
          ...this.state.dataPo,
          job_id: this.props.match.params.jobId,
        },
      },
      () => {
        let formData = new FormData();
        const paramErrorPo = validatePo(this.state.dataPo);
        this.setState({ paramErrorPo });
        formData.append('po_file', this.state.dataPo.po_file);
        formData.append('job_id', this.state.dataPo.job_id);
        formData.append('company_id', this.state.dataPo.company_id);
        formData.append('po_no', this.state.dataPo.po_no);
        formData.append('po_date', this.state.dataPo.po_date);
        formData.append('po_delivery_date', this.state.dataPo.po_delivery_date);
        formData.append('po_description', this.state.dataPo.po_description);
        if (Object.keys(paramErrorPo).length === 0) {
          axios
            .post(
              config.API_URL + "/po/create_po_scm",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                    dataPo: {
                      ...this.state.dataPo,
                      company_id: "",
                      po_no: "",
                      po_date: "",
                      po_delivery_date: "",
                      po_description: "",
                      po_file: "",
                    },
                  });
                }
                this.getListPo();
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataPo.po_no);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleSubmitUpdatePo = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        let formData = new FormData();
        const paramErrorPo = validatePo(this.state.dataUpdatePo);
        this.setState({ paramErrorPo });
        formData.append('po_id', this.state.dataUpdatePo.po_id);
        formData.append('po_file', this.state.dataUpdatePo.po_file);
        formData.append('job_id', this.state.dataUpdatePo.job_id);
        formData.append('company_id', this.state.dataUpdatePo.company_id);
        formData.append('po_no', this.state.dataUpdatePo.po_no);
        formData.append('po_date', this.state.dataUpdatePo.po_date);
        formData.append('po_delivery_date', this.state.dataUpdatePo.po_delivery_date);
        formData.append('po_description', this.state.dataUpdatePo.po_description);
        if (Object.keys(paramErrorPo).length === 0) {
          axios
            .post(
              config.API_URL + "/po/update_po_scm",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: config.TOKEN,
                },
              }
            )
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data.message);
                  this.setState({
                    isLoading: false,
                  });
                }
                this.getListPo();
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(this.state.dataUpdatePo.po_no);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  handleChangeStringPo = (event) => {
    this.setState({
      dataPo: {
        ...this.state.dataPo,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeStringUpdatePo = (event) => {
    this.setState({
      dataUpdatePo: {
        ...this.state.dataUpdatePo,
        [event.target.name]: event.target.value,
      },
    });
  }

  onFileChangeDocPO = (e) => {
    var file = e.target.files[0]
    if(file.type === 'application/pdf'){
      if(file.size < 40960000) {
        this.setState({
          dataPo: {
            ...this.state.dataPo,
            po_file: file
          }
        })
      } else {
        this.failedAlert("File size max 5mb");  
      }
    } else {
      this.failedAlert("File format must be in *.pdf");
    }
  }

  handleCancle = () => {
    this.setState({ 
      updatePo: false,
      selectedOptionCompanies: [],
     })
  }

   removePo = (data) => {
    axios
      .post(
        config.API_URL + "/po/delete_po_scm",
        { po_id: data.po_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertPo();
          this.getListPo();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  
  confirmedAlertPo = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Po has been deleted.
        </SweetAlert>
      ),
    });
  };

  confirmAlertPo = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removePo(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.po_no}`} po, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };

  handlePoDetails = (data) => {
    this.props.history.push("/admin/po-scm-details/"+data.po_id);
  }

  handleUploadExcel = () => {
    this.setState({
      isUploadExcel: !this.state.isUploadExcel
    })
  }
  onFileChange = (e) => {
    this.setState({ dataExcel: e.target.files[0] });
  };

  onFileUpload = () => {
    this.setState({ isLoading: true });
    if(this.state.dataExcel != null){
        let file = this.state.dataExcel;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.name.match(/\.(xlsx|xls)$/)) {
            var formData = new FormData();
            formData.append("file", this.state.dataExcel);
            formData.append("job_id", this.props.match.params.jobId);
            axios
              .post(config.API_URL + "/po/bulk_po", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization": config.TOKEN
                },
              })
              .then(
                (res) => {
                  if (this.mounted) {
                    this.successAlert(res.data.message);
                    this.setState({ isLoading: false });
                    this.getListPo();
                  }
                },
                (err) => {
                  if (this.mounted) {
                    if (err.response !== undefined) {
                      this.failedAlert(err.response.data.message);
                      this.setState({ isLoading: false });
                    }
                  }
                }
              );
        } else {
            this.failedAlert("Invalid Format File");
            this.setState({ isLoading: false });
        }
    } else {
        this.failedAlert("Field is required");
        this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <>
        <StandardHeader name="Job Details" parentName="Jobs" link="jobs-scm" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Job Details</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job Name</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="job_name"
                            placeholder="Job Name"
                            defaultValue={this.state.data.job_name}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.job_name ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.job_name}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job No</label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="job_no"
                            placeholder="Job No"
                            defaultValue={this.state.data.job_no}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.job_no ? "is-invalid" : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.job_no}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Job Description
                          </label>
                          <Input
                            type="text"
                            onKeyPress={this.keyPressed}
                            name="job_description"
                            placeholder="Job Description"
                            defaultValue={this.state.data.job_description}
                            onChange={this.handleChangeString}
                            required
                            className={
                              this.state.paramError.job_description
                                ? "is-invalid"
                                : ""
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.paramError.job_description}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="success"
                          className="btn-icon"
                          type="button"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.handleSubmit()}
                        >
                          {this.state.isLoading ? (
                            <>
                              <span className="btn-inner--text">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                Save
                              </span>
                            </>
                          ) : (
                            <span className="btn-inner--text">Save</span>
                          )}
                        </Button>
                      </Col>

                      <Col
                        style={{ marginTop: 20 }}
                        className="col-6 col-md-3 col-sm-6"
                        xs="12"
                      >
                        <Button
                          block
                          color="danger"
                          disabled={this.state.isLoading ? true : false}
                          onClick={() => this.confirmAlert(this.state.data)}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row className="mb-4">
            <Col>
              <Button
                color="success"
                type="button"
                className="btn-icon"
                onClick={() => this.handleUploadExcel()}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fa fa-file-excel-o" />
                </span>
                <span className="btn-inner--text">Bulk Data PO</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.isUploadExcel &&
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Bulk Data PO</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label className="form-control-label">Excel</label>
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileLang"
                                lang="en"
                                type="file"
                                onChange={(e) => this.onFileChange(e)}
                              />
                              <label className="custom-file-label" htmlFor="customFileLang">
                                Select file
                                    </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col style={{ marginTop: 20 }} className="col-6 col-md-3 col-sm-6" xs="12">
                          <Button
                            block
                            color='success'
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.onFileUpload()}
                          >
                            {
                              this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                        Submit
                                    </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Submit</span>
                              )
                            }
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {this.state.updatePo ? "Update PO" : "Create PO"}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">No</label>
                        <Input
                          type="text"
                          onKeyPress={this.keyPressed}
                          name="po_no"
                          placeholder="No"
                          value={
                            this.state.updatePo
                              ? this.state.dataUpdatePo.po_no
                              : this.state.dataPo.po_no
                          }
                          required
                          className={
                            this.state.paramErrorPo.po_no ? "is-invalid" : ""
                          }
                          onChange={
                            this.state.updatePo
                              ? this.handleChangeStringUpdatePo
                              : this.handleChangeStringPo
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorPo.po_no}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Company</label>
                      <AsyncSelect
                          cacheOptions
                          defaultOptions
                          styles={styleInputSelect}
                          value={this.state.selectedOptionCompanies}
                          placeholder={<div>Select</div>}
                          loadOptions={this.fetchDataCompanies}
                          onChange={(e) => {
                            this.state.updatePo ? this.onSearchChangeUpdatePo(e):this.onSearchChangePo(e);
                          }}
                        />
                    </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="po_date"
                          placeholder="Date"
                          value={
                            this.state.updatePo
                              ? this.state.dataUpdatePo.po_date
                              : this.state.dataPo.po_date
                          }
                          required
                          className={
                            this.state.paramErrorPo.po_date ? "is-invalid" : ""
                          }
                          onChange={
                            this.state.updatePo
                              ? this.handleChangeStringUpdatePo
                              : this.handleChangeStringPo
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorPo.po_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Delivery Date
                        </label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="po_delivery_date"
                          placeholder="Delivery Date"
                          value={
                            this.state.updatePo
                              ? this.state.dataUpdatePo.po_delivery_date
                              : this.state.dataPo.po_delivery_date
                          }
                          required
                          className={
                            this.state.paramErrorPo.po_delivery_date
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updatePo
                              ? this.handleChangeStringUpdatePo
                              : this.handleChangeStringPo
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorPo.po_delivery_date}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <label className="form-control-label">File</label>
                      <Form>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="customFileLang"
                            lang="en"
                            type="file"
                            name="po_file"
                            onChange={this.onFileChangeDocPO}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFileLang"
                          >
                            Select file
                          </label>
                        </div>
                        <small>
                            File format *.pdf (max 5mb)
                        </small>
                      </Form>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="po_description"
                          placeholder="Description"
                          value={
                            this.state.updatePo
                              ? this.state.dataUpdatePo.po_description
                              : this.state.dataPo.po_description
                          }
                          required
                          className={
                            this.state.paramErrorPo.po_description
                              ? "is-invalid"
                              : ""
                          }
                          onChange={
                            this.state.updatePo
                              ? this.handleChangeStringUpdatePo
                              : this.handleChangeStringPo
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramErrorPo.po_description}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <>
                      {this.state.updatePo ? (
                        <>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="success"
                              className="btn-icon"
                              type="button"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() => this.handleSubmitUpdatePo()}
                            >
                              {this.state.isLoading ? (
                                <>
                                  <span className="btn-inner--text">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                    Save
                                  </span>
                                </>
                              ) : (
                                <span className="btn-inner--text">Save</span>
                              )}
                            </Button>
                          </Col>
                          <Col
                            style={{ marginTop: 20 }}
                            className="col-6 col-md-3 col-sm-6"
                            xs="12"
                          >
                            <Button
                              block
                              color="second"
                              disabled={this.state.isLoading ? true : false}
                              onClick={() => this.handleCancle()}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            color="success"
                            className="btn-icon"
                            type="button"
                            disabled={this.state.isLoading ? true : false}
                            onClick={() => this.handleSubmitPo()}
                          >
                            {this.state.isLoading ? (
                              <>
                                <span className="btn-inner--text">
                                  <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                                  Submit
                                </span>
                              </>
                            ) : (
                              <span className="btn-inner--text">Submit</span>
                            )}
                          </Button>
                        </Col>
                      )}
                    </>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <ListPo
                  list={this.state.listPo}
                  info={this.state.data.job_no}
                  update={this.handleUpdatePo}
                  delete={this.confirmAlertPo}
                  details={this.handlePoDetails}
                />
              </div>
            </div>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataJobsSCM: state.jobs_scm.dataJobsSCM,
  };
};
export default connect(mapStateToProps)(JobsSCMDetails);
