import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import {
  Card,
  CardHeader,
  Alert,
  Col,
  Row,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const ActivitesSCM = (props) => {
  const info = props.info;
  const actionsDate = (cell, row) => {
    return moment(cell).format("LL");
  };
  const actionDateTime = (cell, row) => {
    return moment(cell).format("lll");
  };
  return (
    <>
      <Card>
        <ToolkitProvider
          data={props.list}
          keyField="activity_id"
          columns={[
            {
              dataField: "username",
              text: "Name",
              sort: true,
            },
            {
              dataField: "activity_name",
              text: "Activity",
              sort: true,
            },
            {
              dataField: "created_at",
              text: "Created At",
              sort: true,
              formatter: actionDateTime,
            },
          ]}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">{info}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
                wrapperClasses="table-responsive"
                striped
                noDataIndication={() => (
                  <div style={{ height: 300 }}>
                    <Alert style={{ marginLeft: -30 }} color="secondary">
                      <strong>Data not found!</strong> Please check again!
                    </Alert>
                  </div>
                )}
              />
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </>
  );
};

export default ActivitesSCM;
