import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import moment from "moment";
import StandardHeader from "components/Headers/StandardHeader";
import DocumentsVendor from "./DocumentsVendor/DocumentsVendor";
import KickoffVendor from "./KickoffVendor/KickoffVendor";
import MaterialsVendor from "./MaterialsVendor/MaterialsVendor";
import ProgReportVendor from "./ProgReportVendor/ProgReportVendor";
import PccVendor from "./PccVendor/PccVendor";
import QualityVendor from "./QualityVendor/QualityVendor";
import CompleteVendor from "./CompleteVendor/CompleteVendor";
import TaskFeedVendor from "./TaskFeedVendor/TaskFeedVendor";
import TaskFeedAssign from "./TaskFeedVendor/TaskFeedAssign";
import { validateTaskFeed } from "services/validate";
class PoVendorDetails extends PureComponent {
  state = {
    data: {},
    activeTab: "1",
    isLoading: false,
    alert: null,
    paramError: {},
    dataTaskFeed: [],
    taskFeedModalUpdateComplete: false,
    filledTaskFeed: {
      task_feed_description: "",
      start_date: "",
      due_date: "",
    },
    dataSubmitCompleteTask: {
      task_completed: "",
      task_feed_id: "",
      note: "",
      task_feed_description: ""
    },
    filledDataTaskFeedAssign: {
      email: config.EMAIL,
      task_feed_owner: 'supplier',
      start_date: '',
      due_date: '',
      task_feed_description : '',
    },
    filledDataTaskFeedUpdateAssign: {},
    dataTaskFeedAssign: [],
    taskFeedModalAssign: false,
    taskFeedModalUpdateAssign: false,
    paramErrorTaskFeedAssign: {},
    paramErrorTaskFeedUpdateAssign:{}
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount = async () => {
    this.mounted = true;
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_vendor",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeed();
      this.getTaskFeedAssign();
      this.setState({
        data: response.data.data[0],
        dataSubmitCompleteTask: {
          task_completed: response.data.data[0].task_completed,
          task_feed_id: response.data.data[0].task_feed_id,
          note: response.data.data[0].note,
          task_feed_description: response.data.data[0].task_feed_description
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleRefresh = async () => {
    try {
      const response = await axios.post(
        config.API_URL + "/po/view_po_vendor",
        { po_id: this.props.match.params.poId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.getTaskFeed();
      this.getTaskFeedAssign();
      this.setState({
        data: response.data.data[0],
        dataSubmitCompleteTask: {
          task_completed: response.data.data[0].task_completed,
          task_feed_id: response.data.data[0].task_feed_id,
          note: response.data.data[0].note,
          task_feed_description: response.data.data[0].task_feed_description
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
  getTaskFeed = async () => {
    try {
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_vendor",
        { po_id: this.props.match.params.poId, task_feed_owner: 'purchaser' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        dataTaskFeed: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getTaskFeedAssign = async () => {
    try {
      const response_task_feed = await axios.post(
        config.API_URL + "/task_feed/list_task_feed_vendor",
        { po_id: this.props.match.params.poId, task_feed_owner: 'supplier' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      );
      this.setState({
        dataTaskFeedAssign: response_task_feed.data.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeInt = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };

  handleSubmit = () => {
    console.log("#");
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleUpdateStatus = (e) => {
    this.setState({
      taskFeedModalUpdateComplete: true,
      filledTaskFeed: e,
      dataSubmitCompleteTask: {
        task_feed_id: e.task_feed_id,
        task_completed: e.task_completed,
        note: e.note,
        task_feed_description: e.task_feed_description
      },
    });
  };
  handleSubmitTaskFeed = () => {
    axios
      .post(
        config.API_URL + "/task_feed/update_task_completed_vendor",
        {...this.state.dataSubmitCompleteTask, po_id: this.state.data.po_id, company_id: this.state.data.company_id},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.message);
            this.getTaskFeed();
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert("Task");
              this.setState({ isLoading: false });
            }
          }
        }
      );
  };
  handleChangeStringTaskFeed = (event) => {
    this.setState({
      dataSubmitCompleteTask: {
        ...this.state.dataSubmitCompleteTask,
        [event.target.name]: parseInt(event.target.value),
      },
    });
  };

  handleChangeStringTaskFeedCompleteNote = (event) => {
    this.setState({
      dataSubmitCompleteTask: {
        ...this.state.dataSubmitCompleteTask,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleTaskFeedUpdateAssign = (e) => {
    this.setState({
      taskFeedModalUpdateAssign: true,
      filledDataTaskFeedUpdateAssign: {
        task_feed_id: e.task_feed_id,
        po_id: e.po_id,
        job_id: e.job_id,
        company_id: e.company_id,
        start_date: e.start_date,
        due_date: e.due_date,
        task_feed_description: e.task_feed_description,
      }
    })
  }

  handleChangeStringTaskFeedAssign = (e) => {
    this.setState({
      filledDataTaskFeedAssign: {
        ...this.state.filledDataTaskFeedAssign,
        [e.target.name]: e.target.value,
      },
    });
  }

  
  handleChangeStringTaskFeedUpdateAssign = (e) => {
    this.setState({
      filledDataTaskFeedUpdateAssign: {
        ...this.state.filledDataTaskFeedUpdateAssign,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmitTaskFeedAssign = () => {
    this.setState({
      filledDataTaskFeedAssign: {
        po_id: this.state.data.po_id,
        job_id: this.state.data.job_id,
        company_id: this.state.data.company_id,
        ...this.state.filledDataTaskFeedAssign
      },
      isLoading: true
    }, () => {
      const paramErrorTaskFeedAssign = validateTaskFeed(this.state.filledDataTaskFeedAssign);
      this.setState({paramErrorTaskFeedAssign});
      if(Object.keys(paramErrorTaskFeedAssign).length === 0) {
        axios
        .post(
          config.API_URL + "/task_feed/create_task_feed_vendor",
          this.state.filledDataTaskFeedAssign,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({
                isLoading: false,
              });

              this.getTaskFeedAssign();
            }
          },
          (err) => {
            if (this.mounted) {
              if (err.response !== undefined) {
                this.failedAlert(this.state.filledDataTaskFeedAssign.task_feed_description);
                this.setState({ isLoading: false });
              }
            }
          }
        );
      } else {
        this.failedAlert("Validation Errors");
        this.setState({
          isLoading: false,
        });
      }
    })
  }

  removeTaskFeedAssign = (data) => {
    axios
      .post(
        config.API_URL + "/task_feed/delete_task_feed_vendor",
        { task_feed_id: data.task_feed_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        () => {
          this.confirmedAlertTaskFeedAssign();
          this.getTaskFeedAssign();
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
  confirmAlertTaskFeedAssign = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.removeTaskFeedAssign(data)}
          onCancel={() => this.hideAlert()}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          Delete {`${data.task_feed_description}`} task, You won't be able to
          revert this!
        </SweetAlert>
      ),
    });
  };
  
  confirmedAlertTaskFeedAssign = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Task has been deleted.
        </SweetAlert>
      ),
    });
  };

  handleSubmitTaskFeedUpdateAssign = () => {
    const paramErrorTaskFeedUpdateAssign = validateTaskFeed(this.state.filledDataTaskFeedUpdateAssign);
    this.setState({paramErrorTaskFeedUpdateAssign, isLoading: true});
    if(Object.keys(paramErrorTaskFeedUpdateAssign).length === 0) {
      axios
      .post(
        config.API_URL + "/task_feed/update_task_feed_vendor",
        this.state.filledDataTaskFeedUpdateAssign,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.successAlert(res.data.message);
            this.setState({
              isLoading: false,
            });
            this.getTaskFeedAssign();
          }
        },
        (err) => {
          if (this.mounted) {
            if (err.response !== undefined) {
              this.failedAlert(this.state.filledDataTaskFeedUpdateAssign.task_feed_description);
              this.setState({ isLoading: false });
            }
          }
        }
      );
    } else {
      this.failedAlert("Validation Errors");
      this.setState({
        isLoading: false,
      });
    }
}

  render() {
    return (
      <>
        <StandardHeader name="Po Details" parentName="Po" link="jobs-vendor" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          PO No : {this.state.data.po_no}
                        </h3>
                      </Col>
                      <Col className="text-right">
                        <Button
                          className="btn-neutral text-uppercase"
                          size="sm"
                          type="button"
                          onClick={() =>
                            window.open(
                              config.BUCKET_URL +
                                this.state.data.po_file,
                              "_blank"
                            )
                          }
                        >
                          <span className="btn-inner--text ml-2">
                            Download PDF
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                  <Row className="mb-4">
                      <Col md="6" xs="6">
                        <Row>
                          <Col>
                            <h5 className="text-uppercase text-muted mb-0">
                              Status
                            </h5>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.data.close_po === 1 ? 'Closed' : 'Open'}
                            </span>
                          </Col>
                          {this.state.data.close_po === 1 ?
                            <Col>
                              <h5 className="text-uppercase text-muted mb-0">
                                Totally Closed At
                            </h5>
                              <span className="h2 font-weight-bold mb-0">
                                {moment(this.state.data.after_close_po).format('ll')}
                              </span>
                            </Col> : null
                          }
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Date</label>
                          <Input
                            type="text"
                            value={moment(this.state.data.po_date).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Delivery Date
                          </label>
                          <Input
                            type="text"
                            value={moment(
                              this.state.data.po_delivery_date
                            ).format("lll")}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Company</label>
                          <Input
                            type="text"
                            value={this.state.data.company_name}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job No</label>
                          <Input type="text" value={this.state.data.job_no} />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">Job Name</label>
                          <Input type="text" value={this.state.data.job_name} />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Created At
                          </label>
                          <Input
                            type="text"
                            value={moment(this.state.data.created_at).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Updated At
                          </label>
                          <Input
                            type="text"
                            value={moment(this.state.data.updated_at).format(
                              "lll"
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Description
                          </label>
                          <Input
                            type="textarea"
                            value={this.state.data.po_description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          {/* <Row>
          <Col className="text-right">
              <Button
                className="btn-neutral text-uppercase"
                size="sm"
                type="button"
                onClick={() => this.handleRefresh()
                }
              >
                <span className="btn-inner--text ml-2">
                  Refresh
                </span>
              </Button>
            </Col>
          </Row> */}
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "1"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  PO Bundle
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "2"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Minutes of Meeting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "3"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Logistic Matter
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "4"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Job Progress Report
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "5"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  Design Changes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "6"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggle("6");
                  }}
                >
                  RN/NC
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={this.state.activeTab === "7"}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.activeTab === "7",
                  })}
                  onClick={() => {
                    this.toggle("7");
                  }}
                >
                  Finish Job
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="mt-4" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <DocumentsVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="2">
                <KickoffVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="3">
                <MaterialsVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="4">
                <ProgReportVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="5">
                <PccVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="6">
                <QualityVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
              <TabPane tabId="7">
                <CompleteVendor
                  data={this.state.data}
                  id={this.props.match.params.poId}
                />
              </TabPane>
            </TabContent>
          </div>
          <Row>
            <Col sm="6">
              <TaskFeedVendor
                list={this.state.dataTaskFeed}
                info={"Task Feed (Purchaser)"}
                update={this.handleUpdateStatus}
                toggleModal={this.toggleModal}
              />
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.taskFeedModalUpdateComplete}
                toggle={() => this.toggleModal("taskFeedModalUpdateComplete")}
              >
                <div className="modal-header">
                  <h6 className="modal-title" id="modal-title-default">
                    Task
                  </h6>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("taskFeedModalUpdateComplete")
                    }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Description
                        </label>
                        <Input
                          type="textarea"
                          onKeyPress={this.keyPressed}
                          name="task_feed_description"
                          placeholder="Description"
                          disabled
                          value={
                            this.state.filledTaskFeed.task_feed_description
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Start Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="start_date"
                          placeholder="Start Date"
                          value={moment(
                            this.state.filledTaskFeed.start_date
                          ).format("YYYY-MM-DD")}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                      <FormGroup>
                        <label className="form-control-label">Due Date</label>
                        <Input
                          type="date"
                          onKeyPress={this.keyPressed}
                          name="due_date"
                          placeholder="Due Date"
                          disabled
                          value={moment(
                            this.state.filledTaskFeed.due_date
                          ).format("YYYY-MM-DD")}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Complete</label>
                        <Input
                          type="select"
                          onKeyPress={this.keyPressed}
                          name="task_completed"
                          placeholder="Complete"
                          onChange={this.handleChangeStringTaskFeed}
                          required
                          value={
                            this.state.dataSubmitCompleteTask.task_completed
                          }
                        >
                          <option value={""}>Select</option>
                          <option value={0}>No</option>
                          <option value={1}>Yes</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <label className="form-control-label">Note</label>
                        <Input
                          type="textarea"
                          name="note"
                          placeholder="Notes"
                          onChange={this.handleChangeStringTaskFeedCompleteNote}
                          required
                          value={
                            this.state.dataSubmitCompleteTask.note
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="success"
                    type="button"
                    onClick={() => this.handleSubmitTaskFeed()}
                  >
                     {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                  </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("taskFeedModalUpdateComplete")
                    }
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </Col>
            <Col sm="6">
              <TaskFeedAssign
                list={this.state.dataTaskFeedAssign}
                info={"Task Feed (Supplier)"}
                update={this.handleTaskFeedUpdateAssign}
                delete={this.confirmAlertTaskFeedAssign}
                toggleModal={this.toggleModal}/>
                <Modal
              className="modal-dialog-centered"
              isOpen={this.state.taskFeedModalAssign}
              toggle={() => this.toggleModal("taskFeedModalAssign")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Add Task
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("taskFeedModalAssign")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label className="form-control-label">Description</label>
                      <Input
                        type="textarea"
                        onKeyPress={this.keyPressed}
                        name="task_feed_description"
                        placeholder="Description"
                        onChange={this.handleChangeStringTaskFeedAssign}
                        required
                        className={
                          this.state.paramErrorTaskFeedAssign.task_feed_description ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedAssign.task_feed_description}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Start Date</label>
                      <Input
                        type="date"
                        onKeyPress={this.keyPressed}
                        name="start_date"
                        placeholder="Start Date"
                        onChange={this.handleChangeStringTaskFeedAssign}
                        required
                        className={
                          this.state.paramErrorTaskFeedAssign.start_date ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedAssign.start_date}
                      </div>
                    </FormGroup>
                  </Col>
                   <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Due Date</label>
                      <Input
                        type="date"
                        onKeyPress={this.keyPressed}
                        name="due_date"
                        placeholder="Due Date"
                        onChange={this.handleChangeStringTaskFeedAssign}
                        required
                        className={
                          this.state.paramErrorTaskFeedAssign.due_date ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedAssign.due_date}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button
                  color="success"
                  type="button"
                  onClick={() => this.handleSubmitTaskFeedAssign()}
                >
                   {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("taskFeedModalAssign")}
                >
                  Close
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.taskFeedModalUpdateAssign}
              toggle={() => this.toggleModal("taskFeedModalUpdateAssign")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Update Task
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("taskFeedModalUpdateAssign")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <label className="form-control-label">Description</label>
                      <Input
                        type="textarea"
                        onKeyPress={this.keyPressed}
                        name="task_feed_description"
                        placeholder="Description"
                        onChange={this.handleChangeStringTaskFeedUpdateAssign}
                        value={this.state.filledDataTaskFeedUpdateAssign.task_feed_description}
                        required
                        className={
                          this.state.paramErrorTaskFeedUpdateAssign.task_feed_description ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedUpdateAssign.task_feed_description}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Start Date</label>
                      <Input
                        type="date"
                        onKeyPress={this.keyPressed}
                        name="start_date"
                        placeholder="Start Date"
                        onChange={this.handleChangeStringTaskFeedUpdateAssign}
                        value={moment(this.state.filledDataTaskFeedUpdateAssign.start_date).format("YYYY-MM-DD")}
                        required
                        className={
                          this.state.paramErrorTaskFeedUpdateAssign.start_date ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedUpdateAssign.start_date}
                      </div>
                    </FormGroup>
                  </Col>
                   <Col className="col-lg-6 col-md-6 col-sm-6" xs="12">
                    <FormGroup>
                      <label className="form-control-label">Due Date</label>
                      <Input
                        type="date"
                        onKeyPress={this.keyPressed}
                        name="due_date"
                        placeholder="Due Date"
                        onChange={this.handleChangeStringTaskFeedUpdateAssign}
                        required
                        value={moment(this.state.filledDataTaskFeedUpdateAssign.due_date).format("YYYY-MM-DD")}
                        className={
                          this.state.paramErrorTaskFeedUpdateAssign.due_date ? "is-invalid" : ""
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.paramErrorTaskFeedUpdateAssign.due_date}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button
                  color="success"
                  type="button"
                  onClick={() => this.handleSubmitTaskFeedUpdateAssign()}
                >
                   {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Save
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Save</span>
                    )}
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("taskFeedModalUpdateAssign")}
                >
                  Close
                </Button>
              </div>
            </Modal>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </>
    );
  }
}

export default PoVendorDetails;
