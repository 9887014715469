/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledTooltip
} from "reactstrap";
// import StandardHeader from "components/Headers/HeaderVendor.js";
// import { validateAddStandardInvoice } from 'services/validate';
import StandardHeader from "components/Headers/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
import Spacer from "components/Headers/Spacer";
import styled from "styled-components";
const today = new Date();
const inDate = today.getDate();
const pic_date = inDate % 2 === 0 ? 2 : 3;
// const nD = new Date().toLocaleString("en-US", {
//   timeZone: "Asia/Bangkok",
// });
const formdo = styled.form`
  img {
    width: 550px;
    height: 95px;
    margin-bottom: -70px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 130px;
    display: block;
    @media (max-width: 991.98px) {
      width: 550px;
      height: 95px;
      margin-bottom: 20px;
    }
    @media (max-width: 575.98px) {
      width: 320px;
      height: 55px;
      margin-bottom: 20px;
    }
  }
`;
class FormOrderDelivery extends PureComponent {
  state = {
    dataPo: [],
    pib: false,
    imgSrc: "",
    thisShow: "",
    data: {
      invoice: pic_date,
      user_id: config.USER_ID,
      company_id: config.COMPANY_ID,
      qty: "",
      forwader: "",
      forwaderContact: "",
      origin: "",
      destination: "",
      fleet: "",
      docNumber: "",
      docDate: "",
      etaDate: "",
    },
    files: {
      dn: null,
      packingList: null,
      shippingDoc: null,
      procedure: null,
    },
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.getPO();
    this.onChangePreview = this.onChangePreview.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleChangeFile = (event) => {
    console.log(event.target.files[0].name)
    this.setState({
      files: {
        ...this.state.files,
        [event.target.name]: event.target.files[0],
      },
      imgSrc : event.target.files[0].name
    },
    // console.log(  [event.target.name] + event.target.files[0],)
    );
  };
  onChangePreview = (datas) => {
    const data = datas;
    // console.log(data);
    this.setState({ thisShow: data });
  };

  onChangePreview = (data) => {
 console.log(data)
    this.setState({
      alert: (
        <SweetAlert
          title=""
          style={{ display: "block", width: "60%" }}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Close"
          btnSize=""
        >
          <object
            width="100%"
            height="790"
            data={data}
            type="application/pdf"
          >
            {" "}
          </object>
        </SweetAlert>
      ),
    });
  };
  handleRemove = (data) => {
    const name = data
    // console.log(name)
    this.setState({
      file: {
        name: "",
      },
    });
  };


  postDataToAPI = () => {
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
        },
        files: {
          ...this.state.files,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("invoice_type", this.state.data.invoice);
        formData.append("user_id", this.state.data.user_id);
        formData.append("company_id", this.state.data.company_id);
        formData.append("purchase_order", this.state.data.purchase_order);
        formData.append("invoice_number", this.state.data.invoice_number);
        formData.append("tax_invoice", this.state.data.tax_invoice);
        formData.append("delivery_note", this.state.data.delivery_note);
        formData.append("bast", this.state.data.bast);
        formData.append("good_receipt", this.state.data.good_receipt);
        formData.append("siuk", this.state.data.siuk);
        formData.append("po_file", this.state.files.po_file);
        formData.append("invoice_file", this.state.files.invoice_file);
        formData.append("tax_invoice_file", this.state.files.tax_invoice_file);
        if (this.state.files.delivery_note_file != null) {
          formData.append(
            "delivery_note_file",
            this.state.files.delivery_note_file
          );
        } else {
          formData.append("delivery_note_file", null);
        }
        if (this.state.files.bast_file != null) {
          formData.append("bast_file", this.state.files.bast_file);
        } else {
          formData.append("bast_file", null);
        }
        if (this.state.files.gr_file != null) {
          formData.append("gr_file", this.state.files.gr_file);
        } else {
          formData.append("gr_file", null);
        }
        if (this.state.files.siuk_file != null) {
          formData.append("siuk_file", this.state.files.siuk_file);
        } else {
          formData.append("siuk_file", null);
        }

        //   for (let i = 0; i < fileUploads.length; i++) {
        //     formData.append(`images[${i}]`, this.state.data.fileUloads[i])
        // }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        //   const paramError = validateAddStandardInvoice(this.state.data, this.state.files);
        //   this.setState({ paramError });
        //   if (Object.keys(paramError).length === 0) {
        axios
          .post(config.API_URL + "/invoice/add_standard_invoice", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: config.TOKEN,
            },
          })
          .then(
            (res) => {
              if (this.mounted) {
                this.successAlert(res.data);
                this.setState({ isLoading: false });
              }
            },
            (err) => {
              if (this.mounted) {
                if (err.response !== undefined) {
                  this.failedAlert(err.response.data.message);
                  this.setState({ isLoading: false });
                }
              }
            }
          );
        // } else {
        //   this.failedAlert("Validation Errors");
        //   this.setState({
        //     isLoading: false,
        //   });
        // }
      }
    );
  };

  getPO = () => {
    if (this.state.isResetLoading === false) {
      if (this.state.isFilterLoading === false) {
        this.setState({
          isFilterLoading: true,
        });
      }
    }
    axios
      .post(
        config.API_URL + "/po/list_po_line_item_od",
        { id: this.props.match.params.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: config.TOKEN,
          },
        }
      )
      .then(
        (res) => {
          if (this.mounted) {
            this.setState(
              {
                data: {
                  id: res.data.data[0].id,
                  po: res.data.data[0].po,
                  poitem: res.data.data[0].poitem,
                  quantity: res.data.data[0].quantity,
                  material: res.data.data[0].material,
                  materialname: res.data.data[0].materialname,
                  unit: res.data.data[0].unit,
                  shippedqty: res.data.data[0].shippedqty,
                  outstandinggr: res.data.data[0].outstandinggr,
                },
                // countTable1: res.data.data.length,
              }
              // () => {
              //   if (this.state.isFilterLoading) {
              //     this.setState({
              //       isFilterLoading: false,
              //       isFilter: true,
              //     });
              //   }
              //   if (this.state.isResetLoading) {
              //     this.setState({
              //       isResetLoading: false,
              //       isFilter: true,
              //     });
              //   }
              // }
            );
          }
        },
        (err) => {
          console.log("error: ", err);
        }
      );
  };
 
  handleChangeString = (event) => {
    // console.log(event.target.value)
    // if (event.target.name ==='qty'){
    //     if(event.target.value === this.state.data.outstandinggr){
    //       this.setState({
    //         data: {
    //           ...this.state.data,
    //           [event.target.name]: event.target.value,
    //         },
    //       });
    //       console.log(1)
    //     } else  if(event.target.value){
    //       console.log(2)
    //       this.setState({
    //         data: {
    //           ...this.state.data,
    //           [event.target.name]: event.target.value,
    //           outstandinggr : this.state.data.quantity - this.state.data.shippedqty
    //         },
    //       });
    //     } else {
    //       console.log(3)
    //       this.setState({
    //         data: {
    //           ...this.state.data,
    //           [event.target.name]: event.target.value,
    //           outstandinggr : this.state.data.outstandinggr - event.target.value
    //         },
    //       });
    //     }
    // } else {
      this.setState({
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      });
    // }
  };
  handleCancel = () => {
    this.props.history.push("/vendor/order-delivery");
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() =>
            this.props.history.push("/vendor/active-reguler-invoice/")
          }
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;added successfully
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data.data}`}
        </SweetAlert>
      ),
    });
  };

  handleSubmitInvoiceStandard = () => {
    this.postDataToAPI();
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  handlePIB = () => {
    this.setState({
      pib: !this.state.pib,
    });
  };



  render() {
    // fontWeight: "bold",
    // fontSize: "11"
    console.log(this.state.files.dn)
    return (
      <>
        <Spacer />
        <Container className=" mb-auto">
          {/* <Card className="bg-white shadow">
            <CardHeader className="bg-light border-2 text-center">
              <h3>Shipping order</h3>
            </CardHeader>
          </Card> */}
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <h4 className="text-muted">Purchase order</h4>
                      <hr className="mt-3 " />
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Order number</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.po}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Order line item</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.poitem}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Unit</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.unit}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <label className="form-control-label">Order product :</label> <br />
                      </FormGroup>
                      <div style={{ fontSize: "9px" }}>
                        {this.state.data.materialname}
                      </div>
                      {/* <Row>
                        <Col>
                        </Col>
                        <Col className="text-right">
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <h4 className="text-muted">
                        Order completion (Quantity)
                      </h4>
                      <hr className="mt-3" />
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Order</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.quantity}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Sent</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.shippedqty}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Remaining</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.outstandinggr}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Receipt (GR)</label>
                          </Col>
                          <Col className="text-right">
                            <h3>{this.state.data.shippedqty}</h3>
                          </Col>
                        </Row>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col className={9}>
              <Card>
                <CardBody>
                <h4 className="text-muted">
                        Shipping
                      </h4>
                      <hr className="mt-3 " />
                  <FormGroup>
                    <label className="form-control-label">
                      Shipping quantity
                    </label>
                    <Input
                      bsSize="sm"
                      type="number"
                      // inputMode="numeric"
                       oninput="this.value = this.value.replace(/\D+/g, '')"
                      max={this.state.data.outstandinggr}
                      min="0"
                      name="qty"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={this.state.data.qty}
                      onChange={this.handleChangeString}
                      required
                      className={
                        this.state.paramError.qty ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.qty}
                    </div>
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Forwader</label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name=" forwader"
                          value={this.state.data.forwader}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.forwader
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.forwader}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Forwader contact
                        </label>
                        <Input
                          bsSize="sm"
                          type="number"
                          name="forwaderContact"
                          value={this.state.data.forwaderContact}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.forwaderContact
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.forwaderContact}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className="form-control-label">
                      Shipping origin
                    </label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="origin"
                      value={this.state.data.origin}
                      onChange={this.handleChangeString}
                      required
                      className={
                        this.state.paramError.origin ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.origin}
                    </div>
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Shipping destination
                        </label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="destination"
                          placeholder="Incoterm Desc"
                          value={this.state.data.destination}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.destination
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.destination}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Shipping fleet
                        </label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="fleet"
                          value={this.state.data.fleet}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.fleet
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.fleet}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className="form-control-label">
                      Shipping doc number
                    </label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="docNumber"
                      value={this.state.data.docNumber}
                      onChange={this.handleChangeString}
                      required
                      className={
                        this.state.paramError.docNumber ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.docNumber}
                    </div>
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Shipping doc date
                        </label>
                        <Input
                          bsSize="sm"
                          type="date"
                          name="docDate"
                          value={this.state.data.docDate}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.docDate
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.docDate}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          ETA delivery date
                        </label>
                        <Input
                          bsSize="sm"
                          type="date"
                          name="etaDate"
                          value={this.state.data.etaDate}
                          onChange={this.handleChangeString}
                          required
                          className={
                            this.state.paramError.etaDate
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.paramError.etaDate}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Delivery note
                        </label>
                      {this.state.files.dn ?
                      (
                        <>
                        <Row>
                        {/* <Col className="col-lg-6 col-md-6 col-sm-6" xs="6"> */}
                            {/* <FormGroup> */}
                              {/* <Button
                                id="dl"
                                size="sm"
                                type="button"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(
                                    config.BUCKET_URL +
                                      this.state.data.company_siuk_file,
                                    "_blank"
                                  )
                                }
                              >
                                <i className="far fa-file-pdf fa-2x"></i>
                              </Button>
                              <UncontrolledTooltip flip delay={0} target={"dl"}>
                                Donwload File
                              </UncontrolledTooltip> */}
                              <UncontrolledTooltip flip delay={0} target={"pr"}>
                                Preview File
                              </UncontrolledTooltip>
                              &emsp;
                              <Button
                                id="pr"
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                }}
                                onClick={() =>
                                  this.onChangePreview(
                                    this.state.imgSrc
                                  )
                                }
                              >
                                <i className="fas fa-file-image fa-2x"></i>
                              </Button>
                            {/* </FormGroup>
                          </Col> */}
                          {/* <Col className="col-lg-6 col-md-6 col-sm-6" xs="6"> */}
                            {/* <FormGroup className="text-right"> */}
                              <Button
                                id="del"
                                onClick={() => this.handleRemove("dn")}
                                color="dark"
                                size="sm"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                  objectFit: "cover",
                                  fontSize: "7px"
                                }}
                              >
                                <i
                                  
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              <UncontrolledTooltip
                                flip
                                delay={0}
                                target={"del"}
                              >
                                Delete
                              </UncontrolledTooltip>
                            {/* </FormGroup> */}
                          {/* </Col> */}
                        </Row>

                        </>
                      ): (
                        <>
                        <Form>
                          <Input
                            bsSize="sm"
                            className="custom-file-input"
                            id="customFileLang"
                            type="file"
                            name="dn"
                            onChange={this.handleChangeFile}
                            required
                            />
                          {this.state.paramError.dn && (
                            <small className="text-warning">
                              {this.state.paramError.dn}
                            </small>
                          )}
                        </Form>
                          </>
                        )
                      }
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Packing list
                        </label>
                        <Form>
                          <Input
                            bsSize="sm"
                            className="custom-file-input"
                            id="customFileLang"
                            type="file"
                            name="packingList"
                            onChange={this.handleChangeFile}
                            required
                          />
                          {this.state.paramError.packingList && (
                            <small className="text-warning">
                              {this.state.paramError.packingList}
                            </small>
                          )}
                        </Form>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          shipping doc
                        </label>
                        <Form>
                          <Input
                            bsSize="sm"
                            className="custom-file-input"
                            id="customFileLang"
                            type="file"
                            name="shippingDoc"
                            onChange={this.handleChangeFile}
                            required
                          />
                          {this.state.paramError.shippingDoc && (
                            <small className="text-warning">
                              {this.state.paramError.shippingDoc}
                            </small>
                          )}
                        </Form>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Unloading procedure
                        </label>
                        <Form>
                          <Input
                            bsSize="sm"
                            className="custom-file-input"
                            id="customFileLang"
                            type="file"
                            name="procedure"
                            onChange={this.handleChangeFile}
                            required
                          />
                          {this.state.paramError.invoice_file && (
                            <small className="text-warning">
                              {this.state.paramError.invoice_file}
                            </small>
                          )}
                        </Form>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center my-4">
                    <Col
                      // style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        size="sm"
                        block
                        color="link"
                        className="btn-icon"
                        type="button"
                        onClick={() => this.handleCancel()}
                      >
                        <span className="btn-inner--text">Cancel</span>
                      </Button>
                    </Col>
                    <Col
                      // style={{ marginTop: 20 }}
                      className="col-6 col-md-3 col-sm-6"
                      xs="12"
                    >
                      <Button
                        size="sm"
                        block
                        color="success"
                        className="btn-icon"
                        type="button"
                        disabled={this.state.isLoading ? true : false}
                        // onClick={() => this.handleSubmitInvoiceStandard()}
                      >
                        {this.state.isLoading ? (
                          <>
                            <span className="btn-inner--text">
                              <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                              Submit
                            </span>
                          </>
                        ) : (
                          <span className="btn-inner--text">Submit</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={4}></Col>
            <Col md={8}></Col>
          </Row>
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default FormOrderDelivery;
