import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import config from "services/config";
import axios from "axios";
import decode from "jwt-decode";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import AdminLayout from "layouts/Admin.js";
import VendorLayout from "layouts/Vendor.js";
import AuthLayout from "layouts/Auth.js";
import globalReduxReducer from "services/globalRedux/globalReduxReducer";
import companyReducer from "views/Admin/Companies/Redux/companyReducer";
import jobsSCMReducer from "views/Admin/JobsSCM/Redux/jobsSCMReducer";
import poVendorReducer from "views/Admin/PoVendor/Redux/poVendorReducer";

console.warn = () => {};
const checkAuthAdmin = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  try {
    var token_local = decode(token);
    var exp = decode(token_local.token);
    var currentTime = new Date().getTime() / 1000;
    if (exp.exp < currentTime) {
      axios
      .post(config.API_URL + "/auth/logout",{ user_id: token_local.user_id}, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      localStorage.clear();
      window.location.reload(true);
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const checkRole  = (role)  =>  {
  try {
    const token = localStorage.getItem("token");
    var token_local = decode(token);
    var token_result = decode(token_local.token);
    if(token_result.role_id === role){
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

const VendorRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() && checkRole(3) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/login" }} />
      )
    }
  />
);
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/auth/sign-in" }} />
      )
    }
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthAdmin() ? (
        <Redirect to={{ pathname: "/admin" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const rootReducer = combineReducers({
  global: globalReduxReducer,
  company: companyReducer,
  jobs_scm: jobsSCMReducer,
  po_vendor: poVendorReducer
});

const storeRedux = createStore(rootReducer);

ReactDOM.render(
  <Provider store={storeRedux}>
    <BrowserRouter>
      <Switch>
        <AdminRoute path="/admin" component={AdminLayout} />
        <VendorRoute path="/vendor" component={VendorLayout} />
        <AuthRoute path="/auth" component={AuthLayout} />
        <Redirect path="/" to="/vendor" />
      </Switch>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);
