import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
// import StandardHeader from "components/Headers/HeaderVendor.js";
import { validateFormOrderAcknowledge } from "services/validate";
import StandardHeader from "components/Headers/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "services/config";
import axios from "axios";
const today = new Date();
const inDate = today.getDate();
const pic_date = inDate % 2 === 0 ? 2 : 3;
// const nD = new Date().toLocaleString("en-US", {
//   timeZone: "Asia/Bangkok",
// });

class FormOrderAcknowledge extends PureComponent {
  state = {
    ol: 0,
    data: [
      {
        purchase_order: "",
        qty: "",
        purchase_order_item: "",
        est_time: "",
        est_date: "",
        reference: "",
      },
    ],
    files: {
      po_file: null,
      invoice_file: null,
      tax_invoice_file: null,
      delivery_note_file: null,
      bast_file: null,
      gr_file: null,
    },
    siuk_file: null,
    isLoading: false,
    alert: null,
    paramError: {},
  };

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleSubmit = () => {
    this.setState(
      {
        // isLoading: true,
        data: {
          ...this.state.data,
        },
        files: {
          ...this.state.files,
        },
      },
      () => {
        let formData = new FormData();
        formData.append("company_id", this.state.data.company_id);
        formData.append("user_id", this.state.data.user_id);
        formData.append("invoice_type", this.state.data.invoice);
        formData.append("purchase_order", this.state.data.purchase_order);

        formData.append(
          "qty",
          this.state.data.qty === "" ? 0 : this.state.data.qty
        );
        formData.append(
          "purchase_order_item",
          this.state.data.purchase_order_item
        );

        formData.append(
          "est_time",
          this.state.data.est_time.replace(":", ".") + ".00"
        );
        formData.append(
          "est_date",
          this.state.data.est_date.replaceAll("-", ".")
        );
        formData.append("reference", this.state.data.reference);

        // if (this.state.files.delivery_note_file != null) {
        //   formData.append(
        //     "delivery_note_file",
        //     this.state.files.delivery_note_file
        //   );
        // } else {
        //   formData.append("delivery_note_file", null);
        // }

        //   for (let i = 0; i < fileUploads.length; i++) {
        //     formData.append(`images[${i}]`, this.state.data.fileUloads[i])
        // }
        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }
        const paramError = validateFormOrderAcknowledge(
          this.state.data,
          this.state.files
        );
        this.setState({ paramError });
        if (Object.keys(paramError).length === 0) {
          axios
            .post(config.API_URL + "/po/form_order_acknowledge", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: config.TOKEN,
              },
            })
            .then(
              (res) => {
                if (this.mounted) {
                  this.successAlert(res.data);
                  this.setState({ isLoading: false });
                }
              },
              (err) => {
                if (this.mounted) {
                  if (err.response !== undefined) {
                    this.failedAlert(err.response.data.message);
                    this.setState({ isLoading: false });
                  }
                }
              }
            );
        } else {
          this.failedAlert("Validation Errors");
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };
  handleChangeFile = (event) => {
    this.setState({
      files: {
        ...this.state.files,
        [event.target.name]: event.target.files[0],
      },
    });
  };
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  handleCancel = () => {
    this.props.history.push("/vendor/order-acknowledge/");
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          // onConfirm={() =>
          //   this.props.history.push("/vendor/active-reguler-invoice/")
          // }
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Invoice&nbsp; <b>{`${data.data}`}</b>&nbsp;added successfully
        </SweetAlert>
      ),
    });
  };
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data.data}`}
        </SweetAlert>
      ),
    });
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  addPoItem = () => {
    // console.log("cekkkkk")
    // this.setState(
    //   data
    //   ...this.state.data,
    //   {
    //   purchase_order: "",
    //   qty: "",
    //   purchase_order_item: "",
    //   est_time: "",
    //   est_date: "",
    //   reference: "",}
    // );
  };
  removePoItem = (i) => {
    let newFormValues = [...this.state.data];
    newFormValues.splice(i, 1);
    this.setState(newFormValues);
    // if (this.state.ol === 0) {
    //   this.setState({
    //     ol: 0,
    //   });
    // } else {
    //   this.setState({
    //     ol: this.state.ol - 1,
    //   });
    // }
  };
  handleChange = (i, e) => {
    let newFormValues = [...this.state.data];
    newFormValues[i][e.target.name] = e.target.value;
    this.setState(newFormValues);
    console.log(newFormValues);
  };
  // poItem = () => {
  //   return this.state.data.map((items, i)=>

  //   <div key={i}>
  //      <input type="text" name={items.qty[i]} value={items.purchase_order[i]} onChange={e => this.handleChange(i, e)} />
  //      {
  //               i ?
  //                 <button type="button"   className="button remove" onClick={() => this.removePoItem(i)}>Remove</button>
  //               : null
  //             }
  //   </div>
  //   )
  // }

  render() {
    // console.log(this.state.data.map)
    // const poItem =
    return (
      <>
        <Container className=" mb-auto">
          <StandardHeader
            parentName="Order Acknowledge Form"
            link="order-acknowledge"
          />
          <Card className="bg-white shadow">
            <CardHeader className="bg-secondary border-2">
              <Row className="text-center">
                <Col xs="12" md="12">
                  <h3 className="mb-0">Order Acknowledge</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} xs={12}>
                  <FormGroup>
                    <label className="form-control-label">
                      Purchase order
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="purchase_order"
                      value={this.state.data.purchase_order}
                      onChange={this.handleChangeString}
                      required
                      maxLength="10"
                      className={
                        this.state.paramError.purchase_order ? "is-invalid" : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.purchase_order}
                    </div>
                  </FormGroup>
                </Col>
                {/* <Col
                  md={1} xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button color="dark" size="sm">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                </Col> */}
                <Col md={3}>
                  <FormGroup>
                    <label className="form-control-label">
                      Quantity
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="qty"
                      value={this.state.data.qty}
                      onChange={this.handleChangeString}
                      required
                      maxLength="13"
                      className={this.state.paramError.qty ? "is-invalid" : ""}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.qty}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={8} sm={10}>
                  <FormGroup>
                    <label className="form-control-label">
                      Purchase order item
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      name="purchase_order_item"
                      value={this.state.data.purchase_order_item}
                      onChange={this.handleChangeString}
                      required
                      maxLength="4"
                      className={
                        this.state.paramError.purchase_order_item
                          ? "is-invalid"
                          : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.purchase_order_item}
                    </div>
                  </FormGroup>
                </Col>
                <Col
                  md={1} sm={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button color="dark" size="sm">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </Button>
                  <Button
                color="primary"
                size="sm"
                onClick={() => this.addPoItem()}
              >
                <i className="ni ni-fat-add" aria-hidden="true"></i>
              </Button>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label className="form-control-label">
                      Time
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="time"
                      name="est_time"
                      value={this.state.data.est_time}
                      onChange={this.handleChangeString}
                      required
                      className={this.state.paramError.est_time ? "is-invalid" : ""}
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.est_time}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label className="form-control-label">
                      Date
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="date"
                      name="est_date"
                      value={this.state.data.est_date}
                      onChange={this.handleChangeString}
                      required
                      maxLength="10"
                      className={
                        this.state.paramError.est_date
                          ? "is-invalid"
                          : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.est_date}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={5}>
                  <FormGroup>
                    <label className="form-control-label">
                      Reference
                    </label>
                    <Input
                      type="textarea"
                      name="reference"
                      value={this.state.data.reference}
                      onChange={this.handleChangeString}
                      maxLength="35"
                      className={
                        this.state.paramError.reference
                          ? "is-invalid"
                          : ""
                      }
                    />
                    <div className="invalid-feedback">
                      {this.state.paramError.reference}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
             
              <p className="font-italic" style={{ marginTop: 20 }}>
                {" "}
                <span className="text-danger">*</span> Required field
              </p>
              <Row className="justify-content-md-center">
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="danger"
                    className="btn-icon"
                    type="button"
                    onClick={() => this.handleCancel()}
                  >
                    <span className="btn-inner--text">Cancel</span>
                  </Button>
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="col-6 col-md-3 col-sm-6"
                  xs="12"
                >
                  <Button
                    block
                    color="success"
                    className="btn-icon"
                    type="button"
                    disabled={this.state.isLoading ? true : false}
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <>
                        <span className="btn-inner--text">
                          <i className="fa fa-circle-o-notch fa-spin fa-fw mr-2" />
                          Submit
                        </span>
                      </>
                    ) : (
                      <span className="btn-inner--text">Submit</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardBody>
            {this.state.alert}
          </Card>
        </Container>
      </>
    );
  }
}

export default FormOrderAcknowledge;
