import React from "react";
import PropTypes from "prop-types"
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
class StandardHeader extends React.Component {
  render() {
    return (
      <>
                  {/* <h6 className="h2 d-inline-block mb-0 active">
                    {this.props.name}
                  </h6>{" "} */}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    {/* <BreadcrumbItem>
                      <Link to={"/vendor/" + this.props.link}>
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem> */}
                    <BreadcrumbItem>
                      <Link to={"/vendor/" + this.props.link} className="active" style={{color: "inherit"}}>
                        <a href="*">
                        <b> {this.props.parentName}</b>
                        </a>
                      </Link>
                    </BreadcrumbItem>
                    {
                      this.props.name ? (<>
                    <BreadcrumbItem
                      aria-current="page"
                      className="active"
                    >
                      {this.props.name}
                    </BreadcrumbItem>
                      </>) : null
                    }
                  </Breadcrumb>
      </>
    );
  }
}

StandardHeader.propTypes = {
    name: PropTypes.string,
    parentName: PropTypes.string,
    link: PropTypes.string,
  };
  
export default StandardHeader;
