import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import config from "services/config";
import { validateProfile } from "services/validate";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Title from "components/Breadcumbs/Title";
import StandardHeader from "components/Headers/StandardHeader";
class MyProfile extends PureComponent {
  state = {
    data: {
      user_id: 0,
      username: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    email_address: "",
    alert: null,
    paramError: {},
  };
  componentDidMount() {
    this.mounted = true;
    this.setState({
      data: {
        ...this.state.data,
        user_id: config.USER_ID,
        username: config.USERNAME,
      },
      email_address: config.EMAIL,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleChangeString = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
  handleSubmit() {
    const paramError = validateProfile(this.state.data);
    this.setState({ paramError });
    if (Object.keys(paramError).length === 0) {
      axios
        .post(
          config.API_URL + "/users/update_myprofile",
          this.state.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.TOKEN,
            },
          }
        )
        .then(
          (res) => {
            if (this.mounted) {
              this.successAlert(res.data.message);
              this.setState({ isLoading: false });
              localStorage.clear();
              window.location.reload(true);
            }
          },
          (err) => {
            if (this.mounted) {
              if (err.response !== undefined) {
                this.failedAlert(err.response.data.message);
                this.setState({ isLoading: false });
              }
            }
          }
        );
    } else {
      this.failedAlert("Validation Errors");
    }
  }
  failedAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Failed"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  successAlert = (data) => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {`${data}`}
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  render() {
    return (
      <div>
        <StandardHeader
          name="My Profile"
          parentName="My Profile"
          link="myprofile"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Profile</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Username
                            </label>
                            <Input
                              type="text"
                              onKeyPress={this.keyPressed}
                              name="username"
                              placeholder="Username"
                              value={this.state.data.username}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramError.username
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramError.username}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              disabled
                              id="input-email"
                              value={this.state.email_address}
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Old Password
                            </label>
                            <Input
                              type="password"
                              onKeyPress={this.keyPressed}
                              name="old_password"
                              placeholder="Old Password"
                              value={this.state.data.old_password}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramError.old_password
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramError.old_password}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              New Password
                            </label>
                            <Input
                              type="password"
                              onKeyPress={this.keyPressed}
                              name="new_password"
                              placeholder="New Password"
                              value={this.state.data.new_password}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramError.new_password
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramError.new_password}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-lg-3 col-md-3 col-sm-6" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Confirm Password
                            </label>
                            <Input
                              type="password"
                              onKeyPress={this.keyPressed}
                              name="confirm_password"
                              placeholder="Confirm Password"
                              value={this.state.data.confirm_password}
                              onChange={this.handleChangeString}
                              required
                              className={
                                this.state.paramError.confirm_password
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.paramError.confirm_password}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col
                          style={{ marginTop: 20 }}
                          className="col-6 col-md-3 col-sm-6"
                          xs="12"
                        >
                          <Button
                            block
                            className="btn-second text-uppercase"
                            type="button"
                            onClick={() => this.handleSubmit()}
                          >
                            <span className="btn-inner--text">Save</span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.alert}
      </div>
    );
  }
}

export default connect()(MyProfile);
